<template>
  <div>
    <nav-header :showMenu="showMenu" content=""></nav-header>
    <div style="width: 1200px;margin: auto;">
      <h1 style="text-align: center;">{{title}}</h1>
      <h6 style="text-align: center;">{{createTime}}</h6>
      <p style="line-height: 30px">{{content}}</p>
    </div>
    <div style="position: absolute;bottom: 0;width: 100%">
      <nav-footer style="width: 100%;height: 100px;background-color: #303030;color:white"></nav-footer>
    </div>

  </div>
</template>

<script>
import NavHeader from '../common/NavHeader.vue'
import NavFooter from '../common/NavFooter.vue'
import moment from "moment";
export default {
  components:{
    NavHeader,
    NavFooter
  },
  data() {
    return {
      showMenu:false,
      title:this.$route.query.title,
      content:this.$route.query.content,
      createTime:this.$route.query.readtime
    }
  },
  created() {
    // console.log(this.$route.query);
    this.dateFormat()
  },
  methods: {
    dateFormat(){
      this.createTime=moment(this.createTime).format("YYYY-MM-DD HH:mm:ss")
    }
  }
}
</script>

<style scoped>

</style>
