export const m = {
  language: "语言",
  chinese: "中文",
  english: "英文",
  shoppingCart: "购物车",
  personalCenter: "个人中心",
  signIn: "登录",
  register: "注册",
  search: "搜索",
  advancedSearch: "高级搜索",
  pleaseContent: "产品名称/产品描述/电池描述",
  helloWelcome: "你好,欢迎来到商城！",
  allClassification: "全部分类",
  homePage: "首页",
  hotProject: "热销产品",
  inventoryProcessing: "库存处理",
  groupProduct: "团购产品",
  aquaticProduct: "戏水产品",
  spotDirectSales: "现货直销",
  desktopMobileGameToys: "桌面手游玩具",
  babyToys: "婴幼儿玩具",
  notPrice: "暂无价格",
  messageCenter: "消息中心",
  commodityInformation: "商品信息",
  price: "价格",
  unitPrice: "单价",
  quantity: "数量",
  packQuantity: "箱量",
  money: "金额",
  totalAmount: "总金额",
  operation: "操作",
  placeOrder: "提交订单",
  confirmOrder: "确认订单",
  totalOrderAmount: "订单总金额为",
  address: "地址",
  determine: "确定",
  cancel: "取消",
  fullName: "姓名",
  mobilePhone: "手机",
  orderManagement: "订单管理",
  addressManagement: "地址管理",
  safeExit: "安全退出",
  date: "日期",
  edit: "编辑",
  delete: "删除",
  areYouDelete: "确认删除吗?",
  addAddress: "新增收货地址",
  receivingAddress: "收货地址",
  orderNumber: "订单编号",
  accountNumber: "账号",
  state: "状态",
  submitTotalPrice: "提交总价",
  finalOffer: "最终报价",
  currencyType: "货币类型",
  submissionTime: "提交时间",
  submitted: "已提交",
  confirmed: "已确认",
  completed: "已完成",
  RMB: "人民币",
  dollar: "美元",
  productName: "产品名称",
  productDescribe: "产品描述",
  batteryDescribe: "电池描述",
  electric: "包电",
  packing: "包装",
  innerBox: "内盒",
  productSize: "产品尺寸",
  outerBoxSize: "外箱尺寸",
  volume: "体积",
  packingQuantity: "装箱数",
  grossWeight: "毛/净重",
  nothing: "无",
  addToCart: "加入购物车",
  productDetails: "商品详情",
  verificationCode: "验证码",
  seeMore: "查看更多",
  more: "更多",
  userName: "用户名",
  password: "密码",
  otherContactInformation: "其他联系方式",
  classification: "分类",
  comprehensive: "综合",
  newest: "最新",
  areYouSureToExit: "确认退出吗?",
  areYouSureToChangeThePassword: "确定修改密码吗?",
  tips: "提示",
  userInformation: "用户信息",
  changePassword: "修改密码",
  oldPassword: "旧密码",
  newPassword: "新密码",
  registrationSucceededToLogin: "注册成功,是否跳转至登录页?",
  loginWasSuccessful: "注册成功",
  instantMessaging: "即时通讯",
  gender: "性别",
  male: "男",
  female: "女",
  email: "邮箱",
  customer: "处理客服",
  customerPhone: "客服电话",
  confirmPrice: "确认价格",
  recommend: "推荐",
  screen: "筛选",
  primaryClassification: "一级分类",
  secondaryClassification: "二级分类",
  threeLevelClassification: "三级分类",
  collect: "收藏",
  operate: "操作",
  payment: "支付",
  code: "条码",
  remarks: "备注",
  serviceQQ: "QQ客服",
  page1:'产品名称',
  page2:'条码',
  page3:'包装',
  page4:'价格',
  page5:'装箱数',
  page6:'是否有图',
  page7:'长',
  page8:'宽',
  page9:'高',
  page10:'日期',
  page11:'请输入',
  page12:'开始日期',
  page13:'结束日期',
  span1:'请输入产品名称',
  span2:'请输入条码',
  span3:'请输入包装',
  span4:'最小价格',
  span5:'最大价格',
  span6:'最小数量',
  span7:'最大数量',
  span8:'请选择',
  span9:'长cm',
  span10:'宽cm',
  span11:'高cm',
};
