<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    // // console.log(this.Response)
  },
};
</script>

<style>
#app {
  font-size: 14px;
}
body {
  margin: 0;
  padding: 0;
}
</style>
