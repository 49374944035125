<template>
  <div>
    <nav-header content=""></nav-header>
    <div class="main_div">
      <el-tabs type="border-card" class="login_from_class">
        <el-tab-pane :label="$t('m.signIn')">
          <el-form :rules="rules1" ref="ruleForm1" :model="ruleForm1" class="loginInput">
            <el-form-item prop="uname">
              <el-input v-model="ruleForm1.uname" :placeholder="$t('m.userName')" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="ruleForm1.password" :placeholder="$t('m.password')" show-password maxlength="20"></el-input>
            </el-form-item>
            <!-- <slide-verify
              ref="slideblock"
              :imgs="images"
              @again="onAgain"
              @fulfilled="onFulfilled"
              @success="onSuccess"
              @fail="onFail"
              @refresh="onRefresh"
              :slider-text="text"
              :accuracy="accuracy"
            ></slide-verify>
            <button @click="handleClick">刷新</button> -->
            <el-form-item prop="code">
              <el-col :span="15">
                <el-input class="input_style" v-model="ruleForm1.code"  :placeholder="$t('m.verificationCode')" maxlength="5" autocomplete="off" @keyup.enter.native="loginSubmit('ruleForm1')"></el-input>
              </el-col>
              <el-col :span="9">
                <img :src="ruleForm1.imageSrc" @click="getCode" style="width: 90%;height:28px;margin-left: 8px;"/>
              </el-col>
            </el-form-item>
            <a style="color: royalblue;cursor: pointer" @click="dialogVisible = true">忘记密码?</a>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="loginSubmit('ruleForm1')">{{$t('m.signIn')}}</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('m.register')">
          <el-form :label-position="labelPosition" label-width="100px" :rules="rules2" ref="ruleForm2" :model="ruleForm2" class="registerInput">
            <el-form-item :label="$t('m.accountNumber')" prop="account">
              <el-input  v-model="ruleForm2.account" :placeholder="$t('m.accountNumber')" size="mini" maxlength="20" s show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.password')" prop="password">
              <el-input   v-model="ruleForm2.password" :placeholder="$t('m.password')" size="mini" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.fullName')">
              <el-input  v-model="ruleForm2.nickName" :placeholder="$t('m.fullName')" size="mini" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.mobilePhone')" prop="phone">
              <el-input  v-model="ruleForm2.phone" :placeholder="$t('m.mobilePhone')" size="mini" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.email')">
              <el-input  v-model="ruleForm2.email" placeholder="email" size="mini" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="QQ">
              <el-input  v-model="ruleForm2.qqNum" placeholder="QQ" size="mini" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.instantMessaging')">
              <el-input  v-model="ruleForm2.wxNum" :placeholder="$t('m.instantMessaging')" size="mini" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.otherContactInformation')">
              <el-input  v-model="ruleForm2.gogleNum" :placeholder="$t('m.otherContactInformation')" size="mini" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.address')">
              <el-input  v-model="ruleForm2.address" :placeholder="$t('m.address')" size="mini" maxlength="100" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.gender')" style="text-align: left">
              <el-radio v-model="ruleForm2.gender" label=1>{{$t('m.male')}}</el-radio>
              <el-radio v-model="ruleForm2.gender" label=2>{{ $t('m.female') }}</el-radio>
            </el-form-item>
            <el-form-item  :label="$t('m.verificationCode')"  prop="code">
              <el-col :span="15">
                <el-input  v-model="ruleForm2.code"  :placeholder="$t('m.verificationCode')" maxlength="5" autocomplete="off" @keyup.enter.native="registerSubmit('ruleForm2')" size="mini"></el-input>
              </el-col>
              <el-col :span="9">
                <img :src="ruleForm2.imageSrc" @click="getCode" style="width: 65%;height:30px;margin-left: 8px;"/>
              </el-col>
            </el-form-item>
            <el-form-item label-width="0" style="text-align: center;margin-top: 20px">
              <el-button type="primary" @click="registerSubmit('ruleForm2')">{{$t('m.register')}}</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
        title="忘记密码"
        width="30%"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
      <el-form :label-position="labelPosition" label-width="100px" :rules="rules3" ref="ruleForm3" :model="ruleForm3" class="registerInput">
        <el-form-item :label="$t('m.accountNumber')" prop="account">
          <el-input  v-model="ruleForm3.account" :placeholder="$t('m.accountNumber')" maxlength="20" s show-word-limit></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.fullName')" prop="nickName">
          <el-input  v-model="ruleForm3.nickName" :placeholder="$t('m.fullName')"  maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.mobilePhone')" prop="phone">
          <el-input  v-model="ruleForm3.phone" :placeholder="$t('m.mobilePhone')" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.email')" prop="email">
          <el-input  v-model="ruleForm3.email" placeholder="email" size="mini" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item :label="$t('m.newPassword')" prop="password">
          <el-input  v-model="ruleForm3.password" :placeholder="$t('m.newPassword')"  maxlength="11" show-word-limit></el-input>
        </el-form-item>
      </el-form>
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="forgetPassword('ruleForm3')">确 定</el-button>
    </el-dialog>
    <nav-footer class="footer_class"></nav-footer>
  </div>
</template>
<script>
import NavHeader from '../common/NavHeader.vue'
import NavFooter from '../common/NavFooter.vue'
import  {uuid}  from 'vue-uuid';
let uuidR;
export default {
  components:{
    NavHeader,
    NavFooter
  },
  data() {
    return {
      dialogVisible:false,
      labelPosition: 'right',
      showMenu:false,
      ruleForm1:{
        uname:'',
        password:'',
        imageSrc:'',
        code:'',
      },
      ruleForm2:{
        uname:'',
        password:'',
        imageSrc:'',
        code:'',
        account:'',
        nickName:'',
        phone:"",
        qqNum:"",
        wxNum:"",
        address:"",
        email:"",
        corporateName:"",
        gender:"",
        gogleNum:"",
        clientUid:""
      },
      ruleForm3:{
        account:'',
        nickName:'',
        phone:'',
        email:'',
        password:''
      },
      rules1: {
        uname: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      },
      rules2: {
        uname: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],/*
        nickName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],*/
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' }
        ],
      },
      rules3:{
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      },
      text: "向右滑动->", // 设置滑块文字
      // 精确度小，可允许的误差范围小；为1时，则表示滑块要与凹槽完全重叠，才能验证成功。默认值为5
      accuracy: 1,
      images: [
        require('../../assets/img/user.jpg')
      ],
    }
  },
  created() {
    this.getCode();
    this.deleteUserName()
  },
  methods:{
    // 验证通过
    onSuccess(times) {
            // console.log('验证通过，耗时 '+ times + '毫秒');
        },
        // 验证失败
        onFail() {
            // console.log("验证不通过");
        },
        // 滑块上的刷新
        onRefresh() {
            // console.log("点击了刷新小图标");
        },
        // 刷新后执行的回调函数
        onFulfilled() {
            // console.log("刷新成功啦！");
        },
        // 检测是否人为操作
        onAgain() {
            // console.log("检测到非人为操作的哦！");
            this.msg = "try again";
            // 刷新
            this.$refs.slideblock.reset();
        },
        // 重置刷新
        handleClick() {
            this.$refs.slideblock.reset();
        },
    forgetPassword(formName){
      this.$refs[formName].validate((valid) => {
          if (!valid) {
            return false;
          } else {
            this.axios({
              method: "post",
              url: "/v1/front/user/forgetPassword",
              data: this.ruleForm3
            }).then((res) => {
              if (res.data.code === 200) {
                this.dialogVisible = false
                this.$message({
                  type: 'success',
                  message: res.data.message
                });

                // console.log(this.response)

              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                });
              }
            })
          }
      })
    },
    deleteUserName(){
      localStorage.removeItem("userName")
    },
    getCode(){
      uuidR = uuid.v1();
      this.ruleForm1.imageSrc=process.env.VUE_APP_URL+"/v1/webUser/getVerification?clientUid="+uuidR
      this.ruleForm2.imageSrc=process.env.VUE_APP_URL+"/v1/webUser/getVerification?clientUid="+uuidR
    },
    loginSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.axios({
            method:"post",
            url:"/v1/front/user/login",
            data:{
              account:this.ruleForm1.uname,
              clientUid:uuidR,
              password:this.ruleForm1.password,
              code:this.ruleForm1.code
            }
          }).then((res)=> {
            if (res.data.code === 200) {
              // console.log(res);
              localStorage.setItem("token", res.headers.token)
              localStorage.setItem("userName", res.data.data.userName)
              localStorage.setItem("roleNum", res.data.data.roleNum)
              this.$router.push('/');
            } else {
              this.getCode();
              this.$message({
                type: 'error',
                message: res.data.message
              });
            }
          })
        }
      })
    },
    registerSubmit(formName){
      this.ruleForm2.clientUid=uuidR
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.axios({
            method: "post",
            url: "/v1/front/user/register",
            data: this.ruleForm2
          }).then((res) => {
            if (res.data.code === 200) {
              this.$confirm(this.$t('m.registrationSucceededToLogin'), this.$t('m.loginWasSuccessful'), {
                confirmButtonText: this.$t('m.determine'),
                cancelButtonText: this.$t('m.cancel'),
                type: 'success'
              }).then(() => {
                //this.$router.push('/login');
                location.reload();
              })
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              });
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.el-input__inner{
  border-color: #abadb1;
}
.login_from_class{
  width: 500px;
  margin: auto;
  z-index:-1;
}
.loginInput .el-form-item {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
}
.registerInput .el-form-item {
  padding-right: 20px;
  margin-bottom: 15px;
}
.input-width{
  width: 300px
}
element.style{
  margin-left: 0;
}
.main_div{
  height: 750px;padding-top: 50px;overflow: auto
}
.footer_class{
  width: 100%;height: 100px;background-color: #303030;color:white
}
</style>
