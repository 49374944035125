<template>
  <div>
    <nav-header :showMenu="showMenu" content="" @changeMeauIndex="changeMeauIndex"
      @switchSeach="switchSeach"></nav-header>
    <div class="home_div">
      <template v-if="meauIndex == 0">
        <el-container>
          <!-- 头部 -->
          <el-header height="428px" class="header">
            <!-- 分类 -->
            <div class="classify" style="display: none;">
              <ul class="ul">
                <li @click="toSearchPage(meunItem.typeCode, meunItem.id)" class="li_div"
                  v-for="(meunItem, key) in meunItems" :key="key">
                  <div class="lidiv_div">
                    <span v-if="$i18n.locale == 'zh_CN'">{{
                      filterName(meunItem)
                    }}</span>
                    <span v-if="$i18n.locale == 'en_US'">{{
                      filterName(meunItem)
                    }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 轮播图 -->
            <div class="carousel">
              <el-carousel :interval="5000" arrow="always" height="420px">
                <el-carousel-item v-for="item in homeImage" :key="item.id">
                  <img :src="getImgUrl(item.imageUrl)" />
                </el-carousel-item>
              </el-carousel>
            </div>
            <!-- 登录 -->
            <div class="right_info">
              <div style="text-align: left">
                <img src="../../assets/img/user.jpg" alt="" />
              </div>
              <div v-if="uname === undefined || uname === null" style="margin-top: 5px">
                <el-row>
                  <el-col :span="12">
                    <el-button type="primary" plain size="small" class="login_button" @click="login">{{ $t("m.signIn")
                      }}</el-button>
                  </el-col>
                  <el-col :span="12">
                    <el-button type="primary" plain size="small" class="login_button" @click="register">{{
                      $t("m.register") }}</el-button>
                  </el-col>
                </el-row>
              </div>
              <div v-else style="margin-top: 5px">{{ uname }}</div>
              <hr />
              <div>
                <h3 style="color: rgb(35, 172, 255)">
                  {{ $t("m.messageCenter") }}
                </h3>
                <div style="text-align: left; color: #333">
                  <ul>
                    <li class="info_style" v-for="(value, key) in infoList" :key="key" @click="useInfo(value)">
                      {{ value.notice.title }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </el-header>
          <categoryNew :id="meauIndex" :content="content" v-if="isShowSeach"></categoryNew>
          <!-- 板块 -->
          <el-main style="padding: 0">
            <MainContent />
          </el-main>
        </el-container>
      </template>
      <template v-else>
        <category :id="meauIndex" :content="content"></category>
      </template>
      <nav-footer></nav-footer>
      <el-dialog title="高级搜索" :visible.sync="showSelectCard" width="80%" :before-close="handleClose">
        <searchNew></searchNew>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import searchNew from "../views/searchNew.vue";
import NavHeader from "../common/NavHeader.vue";
import NavFooter from "../common/NavFooter.vue";
import MainContent from "@/components/ban/MainContent";
import category from "../ban/category.vue";
import categoryNew from "../ban/categoryNew.vue";

export default {
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el; // 获取当前元素
        oDiv.onmousedown = (e) => {
          // console.log("onmousedown");
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;

          document.onmousemove = (e) => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;
            if (left >= document.documentElement.clientWidth - 200) {
              left = document.documentElement.clientWidth - 200;
            }
            if (top <= 180) {
              top = 180;
            }
            oDiv.style.left = left + "px";
            oDiv.style.top = top + "px";
          };

          document.onmouseup = (e) => {
            console.log(e);
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  components: {
    NavHeader,
    NavFooter,
    MainContent,
    category,
    categoryNew,
    searchNew
  },
  data() {
    return {
      showSelectCard: false,
      isShowSeach: false,
      logoImage: "",
      contactList: [],
      QQ: '',
      uname: localStorage.getItem("userName"),
      showMenu: false,
      imageUrl: process.env.VUE_APP_URL + "/v1/commodityManage/previewImage?path=",
      infoList: [],
      meunItems: [],
      homeImage: [],
      leftHomeImage1: "",
      leftHomeImage2: "",
      meauIndex: 0,
      content: "",
    };
  },
  created() {
    if (this.$route.query.meauIndex) {
      this.meauIndex = this.$route.query.meauIndex;
    }
    if (this.$route.query.content) {
      this.content = this.$route.query.content;
    }
    this.getTopClass();
    this.selectInfoList();
    this.selectImageList();
    // this.getCardDetail();
  },
  methods: {
    handleClose() {
      this.showSelectCard = false;
    },
    switchSeach(codeType, id) {
      console.log("触发了")
      // this.showSelectCard = !this.showSelectCard;
      this.isShowSeach = !this.isShowSeach;
      this.$router.push({
        path: "/searchNew",
        query: {
          codeType: codeType,
          classId: id ? id : null,
          meauIndex: "searchNew",
        },
      });
    },
    changeMeauIndex(id, content) {
      this.meauIndex = id;
      this.content = content;
    },
    filterName(name) {
      if (name == null) return;
      // console.log(name);
      let str = JSON.parse(name.name);
      return this.$i18n.locale == "zh_CN" ? str.zh_CN : str.en_US;
    },
    // 获取顶级分类
    getTopClass() {
      this.axios({
        method: "get",
        url: "/v1/commodityType/showTop",
        params: {
          lag: this.$i18n.locale,
          type: 1,
        },
      }).then((res) => {
        // console.log(res.data.data.list, "分类");
        this.meunItems = res.data.data.list;
        // console.log(this.meunItems, "this.meunItems");
      });
    },
    getCardDetail() {
      this.axios({
        method: "get",
        url: "/v1/config/list",
        params: {
          lag: this.$i18n.locale == "zh_CN" ? true : false,
          page: 1,
          size: 10,
          postion: 'link',
        },
      }).then((res) => {
        // console.log('联系方式',res)
        let list = res.data.data.list;
        this.contactList = res.data.data.list;
        for (let i = 0; i < list.length; i++) {
          if (list[i].name == 'QQ') {
            this.QQ = list[i].value;
          }
        }
      });
    },
    useInfo(value) {
      this.$router.push({ path: "/infoMessage", query: { title: value.notice.title, content: value.notice.content, readtime: value.readtime } });
    },
    // 跳转搜索页面
    toSearchPage(codeType, id) {
      this.$router.push({
        path: "/search",
        query: {
          codeType: codeType,
          classId: id ? id : null,
          meauIndex: "search",
        },
      });
    },
    strAnalysis(val) {
      return JSON.parse(val)[this.$i18n.locale];
    },
    selectInfoList() {
      this.axios({
        method: "get",
        url: "/v1/notice/user",
        params: {
          lag: this.$i18n.locale,
        },
      }).then((res) => {
        let list = res.data.data.list;
        for (let i = 0; i < list.length; i++) {
          list[i].notice = JSON.parse(list[i].notice);
        }
        this.infoList = list;
      });
    },
    selectImageList() {
      this.axios({
        method: "get",
        url: "/v1/imageConfig/selectImageConfig",
        params: {
          postion: 12
        },
      }).then(async (res) => {
        // console.log('轮播图', res);
        for (let i = 0; i < res.data.data.list.length; i++) {
          // console.log(res.data.data.list[i]);
          if (res.data.data.list[i].positionNo === "12" && res.data.data.list[i].display) {
            this.homeImage.push(res.data.data.list[i]);
          }
        }
      });
    },
    // 获取图片
    getImgUrl(img) {
      if (img == null) return;
      img = img.replace(",", "");
      return this.imageUrl + `${img}`;
    },
    login() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    toPay() {
      let routeData = this.$router.resolve({ path: "/pay" });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
.h_div {
  margin: 0;
  height: 2px;
  background-color: rgb(35, 172, 255);
  width: 1015px;
}

.info_style {
  margin-top: 10px;
}

.info_style:hover {
  color: rgb(35, 172, 255);
  cursor: pointer;
}

.home_div {
  /* position: absolute; */
  width: 100%;
  /* top: 170px; */
  /* z-index: -1; */
}

.home_div img {
  width: 100%;
  height: 420px;
  object-fit: cover;
  display: block;
}

.commodity_div {
  margin: 20px auto;
  width: 1220px;
}

.commodity_list_div {
  height: 680px;
}

/*
  .commodity_list_div_1{
    height: 2000px;

  }*/

.aside_class {
  width: 200px;
}

.menu_detail_class {
  width: 35%;
  height: 30px;
  border: 1px solid white;
  line-height: 30px;
  color: white;
  margin: 10px 6%;
  border-radius: 3px;
  float: left;
  overflow: hidden;
  cursor: pointer;
}

.goods-items {
  width: 189.5px;
  height: 210px;
  margin-bottom: 10px;
  float: left;
  margin-left: 11.5px;
  cursor: pointer;
}

/* @media only screen and (max-width: 1109px) {
  .right_info {
    display: none;
  }
}
@media only screen and (min-width: 1110px) {
  .right_info {
    right: 1%;
  }
}
@media only screen and (min-width: 1220px) {
  .right_info {
    right: 7%;
  }
}
@media only screen and (min-width: 1440px) {
  .right_info {
    right: 9%;
  }
}
@media only screen and (min-width: 1510px) {
  .right_info {
    right: 11%;
  }
}
@media only screen and (min-width: 1560px) {
  .right_info {
    right: 13%;
  }
}
@media only screen and (min-width: 1640px) {
  .right_info {
    right: 19%;
  }
} */
.right_info {
  /* position: absolute;
  top: 10px; */
  margin-top: 8px;
  width: 180px;
  height: 420px;
  background-color: #ffffffc2;
  z-index: 2;
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid #eee;
}

.right_info img {
  margin-top: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.login_button {
  width: 80px;
  height: 25px;
  line-height: 7px;
}

.login_button a {
  color: #409EFF;
}

.login_button:hover a {
  color: #ffffff;
}

/* .clickStyle:hover {
  cursor: pointer;
  color: rgb(35, 172, 255);
} */

.drag-box {
  position: fixed;
  top: 200px;
  right: 1%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 999;
  width: 190px;
  /* height: 350px; */
  text-align: center;
  border: 1px solid #eee;
}

.hr_div {
  background-color: #eee;
  height: 1px;
  margin-top: 10px;
}

.h4_div {
  text-align: left;
  padding-left: 20px;
}

.h4_div li {
  line-height: 50px;
}

.carousel {
  flex: 1;
  /* width: 800px; */
  height: 420px;
  margin-top: 8px;
  /* margin-left: 8px; */
  margin-right: 8px;
  /* padding-left: 200px; */
}

.header {
  width: 1220px;
  height: 428px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.classify {
  width: 200px;
  height: 428px;
  background-color: #505050;
  position: relative;
}

.li_div {
  height: 60px;
  display: flex;
  align-items: center;
}

.lidiv_div {
  width: 200px;
  padding: 0 2px;
  text-align: center;
  line-height: 60px;
  font-size: 14px;
  color: #fff;
}

.lidiv_div:hover {
  cursor: pointer;
  color: #fff;
  background-color: #e60013;
}

.active {
  color: #fff;
  background-color: #e60013;
}

.two-classify-item {
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  margin-bottom: 10px;
}

.two-classify-item-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.name:hover {
  cursor: pointer;
  color: rgb(35, 172, 255);
}
</style>
