<template>
  <div>
    <nav-header :showMenu="showMenu" content="" @changeMeauIndex="changeMeauIndex"
                @switchSeach="switchSeach"></nav-header>
    <div style="width: 1200px; margin: 40px auto; overflow: hidden;">
      <div>
        <div class="biy_div">
          <div class="bin_div">
            <pic-zoom :url="bigImageUrl" :scale="3"></pic-zoom>
          </div>
          <div class="img_class">
            <div v-for="(value, key) in imageUrlList" :key="key" @mouseover="mouseOver(value)"
              class="small_image_style">
              <img style="width: 100%; height: 100%" :src="path + value" />
            </div>
          </div>
        </div>
        <div class="detail_class">
          <div>
            <h3>
              {{ comDetail.code === null ? $t("m.nothing") : comDetail.code }}
            </h3>
            <h3 v-if="priceDisplay == 'true'">
              <span style="color: red; font-size: 24px" v-if="$i18n.locale === 'zh_CN'">{{
                comDetail.mackPriceRmb === null
                  ? ""
                  : $t("m.price") + ":￥" + comDetail.mackPriceRmb
              }}</span>
              <span style="color: red; font-size: 24px" v-if="$i18n.locale === 'en_US'">{{
                comDetail.mackPriceUsd === null
                  ? ""
                  : $t("m.price") + ":$" + comDetail.mackPriceUsd
              }}</span>
            </h3>
          </div>
          <div style="height: 280px">
            <el-row>
              <el-col :span="24">
                <h5>
                  {{ $t("m.productName") }}:&nbsp;&nbsp;&nbsp;{{
                    comDetail.name
                  }}
                </h5>
              </el-col>
              <el-col :span="24">
                <h5>
                  {{ $t("m.productDescribe") }}:&nbsp;&nbsp;&nbsp;{{
                    comDetail.describe === null
                      ? $t("m.nothing")
                      : comDetail.describe
                  }}
                </h5>
              </el-col>
              <el-col :span="12">
                <h5>
                  {{ $t("m.packing") }}:&nbsp;&nbsp;&nbsp;{{
                    comDetail.packing === null
                      ? $t("m.nothing")
                      : comDetail.packing
                  }}
                </h5>
              </el-col>
              <el-col :span="12">
                <h5>
                  {{ $t("m.productSize") }}:&nbsp;&nbsp;&nbsp;{{
                    comDetail.productSize === null
                      ? $t("m.nothing")
                      : comDetail.productSizeLong +
                      "*" +
                      comDetail.productSizeWide +
                      "*" +
                      comDetail.productSizeHigh
                  }}cm
                </h5>
              </el-col>
              <el-col :span="12">
                <h5>
                  {{ $t("m.packingQuantity") }}:&nbsp;&nbsp;&nbsp;{{
                    comDetail.ctn === null ? $t("m.nothing") : comDetail.ctn
                  }}
                </h5>
              </el-col>
              <el-col :span="12">
                <h5>
                  {{ $t("m.innerBox") }}:&nbsp;&nbsp;&nbsp;{{
                    comDetail.innerBox === null
                      ? $t("m.nothing")
                      : comDetail.innerBox
                  }}
                </h5>
              </el-col>

              <el-col :span="12">
                <h5>
                  {{ $t("m.outerBoxSize") }}:&nbsp;&nbsp;&nbsp;{{
                    comDetail.outerSize === null
                      ? $t("m.nothing")
                      : comDetail.outherSizeLong +
                      "*" +
                      comDetail.outherSizeWide +
                      "*" +
                      comDetail.outherSizeHigh
                  }}cm
                </h5>
              </el-col>
              <el-col :span="12" v-if="$i18n.locale == 'zh_CN'">
                <h5>
                  {{ $t("m.volume") }}:&nbsp;&nbsp;&nbsp;{{
                    comDetail.volume === null
                      ? $t("m.nothing")
                      : comDetail.volume
                  }}m³
                </h5>
              </el-col>
              <el-col :span="12" v-if="$i18n.locale == 'en_US'">
                <h5>
                  cft:&nbsp;&nbsp;&nbsp;{{
                    comDetail.cft === null ? $t("m.nothing") : comDetail.cft
                  }}
                </h5>
              </el-col>
              <el-col :span="12">
                <h5>
                  {{ $t("m.grossWeight") }}:&nbsp;&nbsp;&nbsp;{{
                    comDetail.grossWeight + "/" + comDetail.netWeight + "kg"
                  }}
                </h5>
              </el-col>
            </el-row>
          </div>
          <div style="display: inline-block">
            <span style="font-size: 16px">{{ $t("m.packQuantity") }}:</span>
            <el-input-number size="mini" v-model="packNum" @change="handleChange" :min="0" :max="99999"
              style="margin-bottom: 10px;"></el-input-number>
            <span style="font-size: 16px; margin-left: 30px">{{ $t("m.quantity") }}:</span>
            <el-input-number size="mini" v-model="num" @change="handleChange" :min="0" :max="99999"
              style="margin-bottom: 10px;"></el-input-number>
            <el-button type="primary" plain @click="addCart" style="margin-left: 30px;">{{ $t("m.addToCart")
              }}</el-button>
          </div>
        </div>
        <div class="right_div window" @mousedown="dragStart" @touchstart="dragStart" ref="window" id="drag" >
          <img :src="getImgUrl(this.logoImage)" alt="" style="width: 100%;height: auto;display: block;" />
          <div class="hr_div"></div>
          <ul class="h4_div">
            <li v-for="(item, index) in contactList" :key="index">
              <div v-if="item.name != 'QQ'">{{ item.name }}：{{ item.value }}</div>
            </li>
            <li>
              <!-- <el-button
                @click="toPay"
                type="primary"
                plain
                size="small"
                class="login_button"
                style="margin-left: 22px; width: 100px"
                >支付方式</el-button
              > -->
              <el-button v-if="QQ" type="primary" plain size="small" class="login_button"
                style="margin-left: 50px; width: 100px">
                <a :href="'tencent://message/?uin=' + QQ + '&Site=Sambow&Menu=yes'" rel="nofollow">
                  {{ $t("m.serviceQQ") }}
                </a>
              </el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="main_div">
        <div class="title_div">
          <span class="font_color">{{ $t("m.productDetails") }}</span>
        </div>
        <div style="text-align: left">
          <div>
            <h4 style="width: 96%">
              {{ $t("m.productName") }}:&nbsp;&nbsp;&nbsp;{{ comDetail.name }}
            </h4>
          </div>
          <div>
            <h4 style="width: 96%">
              {{ $t("m.productDescribe") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.describe === null
                  ? $t("m.nothing")
                  : comDetail.describe
              }}
            </h4>
          </div>
          <div>
            <h4>
              {{ $t("m.packing") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.packing === null ? $t("m.nothing") : comDetail.packing
              }}
            </h4>
            <h4>
              {{ $t("m.productSize") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.productSize === null
                  ? $t("m.nothing")
                  : comDetail.productSizeLong +
                  "*" +
                  comDetail.productSizeWide +
                  "*" +
                  comDetail.productSizeHigh
              }}cm
            </h4>
            <h4>
              {{ $t("m.packingQuantity") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.ctn === null ? $t("m.nothing") : comDetail.ctn
              }}
            </h4>
            <h4>
              {{ $t("m.innerBox") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.innerBox === null
                  ? $t("m.nothing")
                  : comDetail.innerBox
              }}
            </h4>
            <h4>
              {{ $t("m.outerBoxSize") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.outerSize === null
                  ? $t("m.nothing")
                  : comDetail.outherSizeLong +
                  "*" +
                  comDetail.outherSizeWide +
                  "*" +
                  comDetail.outherSizeHigh
              }}cm
            </h4>
          </div>
          <div>
            <h4>
              {{ $t("m.volume") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.volume === null ? $t("m.nothing") : comDetail.volume
              }}m³
            </h4>
            <h4 v-if="$i18n.locale == 'en_US'">
              cft:&nbsp;&nbsp;&nbsp;{{
                comDetail.cft === null ? $t("m.nothing") : comDetail.cft
              }}
            </h4>
            <h4>
              {{ $t("m.grossWeight") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.grossWeight + "/" + comDetail.netWeight + "kg"
              }}
            </h4>
            <!-- <h4>
              {{ $t("m.netWeight") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.netWeight === null
                  ? $t("m.nothing")
                  : comDetail.netWeight
              }}
            </h4> -->
            <!-- <h4>
              {{ $t("m.electric") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.isElectric === null
                  ? $t("m.nothing")
                  : comDetail.isElectric
              }}
            </h4> -->
          </div>
          <div>
            <!-- <h4 style="width: 96%">
              {{ $t("m.batteryDescribe") }}:&nbsp;&nbsp;&nbsp;{{
                comDetail.batteryDescribe === null
                  ? $t("m.nothing")
                  : comDetail.batteryDescribe
              }}
            </h4> -->
          </div>
        </div>
        <hr />
        <div>
          <div v-for="(value, key) in imageUrlList" :key="key">
            <img style="width: 90%; padding-bottom: 16px" :src="path + value" />
          </div>
        </div>
      </div>
    </div>
    <div style="float: left; width: 100%">
      <nav-footer class="footer_class"></nav-footer>
    </div>
  </div>
</template>

<script>
import PicZoom from "vue-piczoom";
import NavHeader from "../common/NavHeader.vue";
import NavFooter from "../common/NavFooter.vue";
export default {
  components: {
    NavHeader,
    PicZoom,
    NavFooter,
  },
  data() {
    return {
      logoImage: "",
      packNum: 0,
      showMenu: false,
      num: 0,
      id: this.$route.query.id,
      priceDisplay: this.$route.query.priceDisplay,
      comDetail: {},
      contactList: [],
      QQ: '',
      imageUrlList: [],
      path: process.env.VUE_APP_URL + "/v1/commodityManage/previewImage?path=",
      bigImageUrl: "",
    };
  },
  created() {
    // console.log(this.priceDisplay)
    this.getDetail();
    this.getCardDetail();
    this.selectImageList();
  },
  methods: {
    dragStart(event) {
      console.log('1')
      // 阻止默认的拖拽行为
      event.preventDefault();

      // 保存鼠标或触摸的初始位置
      this.startX = event.touches ? event.touches[0].clientX : event.clientX;
      this.startY = event.touches ? event.touches[0].clientY : event.clientY;

      // 保存窗口的初始位置
      this.startWindowLeft = this.$refs.window.offsetLeft;
      this.startWindowTop = this.$refs.window.offsetTop;

      // 添加事件监听
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('touchmove', this.drag);
      document.addEventListener('mouseup', this.dragEnd);
      document.addEventListener('touchend', this.dragEnd);
    },
    drag(event) {
      // 计算移动的距离
      const dx = event.touches ? event.touches[0].clientX : event.clientX;
      const dy = event.touches ? event.touches[0].clientY : event.clientY;

      const newLeft = this.startWindowLeft + dx - this.startX;
      const newTop = this.startWindowTop + dy - this.startY;

      // 更新窗口位置
      this.$refs.window.style.left = `${newLeft}px`;
      this.$refs.window.style.top = `${newTop}px`;
    },
    dragEnd() {
      // 移除事件监听
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('touchmove', this.drag);
      document.removeEventListener('mouseup', this.dragEnd);
      document.removeEventListener('touchend', this.dragEnd);
    },
    selectImageList() {
      this.axios({
        method: "get",
        url: "/v1/imageConfig/selectImageConfig",
        params: {
          postion: 11
        },
      }).then(async (res) => {
        // console.log('轮播图', res);
        for (let i = 0; i < res.data.data.list.length; i++) {
          if (res.data.data.list[i].positionNo === "11" && res.data.data.list[i].display) {
            this.logoImage = res.data.data.list[i].imageUrl;
            return false;
          }
        }
      });
    },
    // 获取图片
    getImgUrl(img) {
      if (img == null) return;
      img = img.replace(",", "");
      return this.path + `${img}`;
    },
    getCardDetail() {
      this.axios({
        method: "get",
        url: "/v1/config/list",
        params: {
          lag: this.$i18n.locale == "zh_CN" ? true : false,
          page: 1,
          size: 10,
          postion: 'link',
        },
      }).then((res) => {
        // console.log('联系方式',res)
        this.contactList = res.data.data.list;
        let list = res.data.data.list;
        for (let i = 0; i < list.length; i++) {
          if (list[i].name == 'QQ') {
            this.QQ = list[i].value;
          }
        }
      });
    },
    addCart() {
      // console.log("id:" + this.id);
      if (this.num <= 0 && this.packNum <= 0) {
        this.$message({
          type: 'warning',
          message: this.$i18n.locale == "zh_CN" ? "请选择购买数量" : "Please select the quantity to purchase"
        });
        return false;
      }
      this.axios({
        method: "post",
        url: "/v1/shoppingCart/addToCart",
        params: {
          commodityId: this.id,
          count: this.num + this.packNum * this.comDetail.ctn,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: this.$i18n.locale == "zh_CN" ? "添加成功" : "Add successfully",
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    mouseOver(value) {
      this.bigImageUrl = this.path + value;
    },
    changeMeauIndex(id, content) {
      this.meauIndex = id;
      this.content = content;
    },
    switchSeach(codeType, id) {
      console.log("触发了")
      // this.showSelectCard = !this.showSelectCard;
      this.isShowSeach = !this.isShowSeach;
      this.$router.push({
        path: "/searchNew",
        query: {
          codeType: codeType,
          classId: id ? id : null,
          meauIndex: "searchNew",
        },
      });
    },
    // 获取详情
    getDetail() {
      this.axios({
        method: "get",
        url: "/v1/front/manage/queryCommodityDetail",
        params: {
          lag: this.$i18n.locale,
          id: this.id,
        },
      }).then((res) => {
        this.comDetail = res.data.data.detail;
        if (
          this.comDetail.imageUrl !== null &&
          this.comDetail.imageUrl !== undefined
        ) {
          this.imageUrlList = this.comDetail.imageUrl.split(",").filter(url => url).slice(0, 5);
          this.bigImageUrl = this.path + this.imageUrlList[0];
        }
      });
    },
    handleChange() { },
  },
};
</script>

<style scoped>
.biy_div {
  width: 350px;
  float: left;
}

.bin_div {
  height: 350px;
  border: 1px solid #eee;
}

.font_color {
  color: #555555;
}

.img_class {
  height: 62px;
  width: 340px;
  background-color: #f9f9f9;
  border: 1px solid #eee;
  border-top: none;
  padding: 10px 5px;
}

.detail_class {
  float: left;
  width: 520px;
  text-align: left;
  margin-left: 40px;
}

.right_div {
  float: left;
  width: 240px;
  min-height: 350px;
  text-align: center;
  margin-left: 20px;
  border: 1px solid #eee;
  position: absolute;
  right: 10%;
}

.hr_div {
  background-color: #eee;
  height: 1px;
  margin-top: 10px;
}

.h4_div {
  text-align: left;
  padding-left: 20px;
}

.h4_div li {
  line-height: 50px;
}

.main_div {
  float: left;
  margin-top: 60px;
  width: 100%;
}

.title_div {
  height: 30px;
  background-color: #f9f9f9;
  text-align: left;
  line-height: 30px;
}

.small_image_style {
  margin-left: 1px;
  border: 1px solid #eee;
  float: left;
  width: 19%;
  height: 100%;
}

.small_image_style :hover {
  border: 1px solid #ee0a24;
}

h4,
h5 {
  color: #555555;
  margin: 8px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
}

h4 {
  display: inline-block;
  width: 18%;
  padding-left: 20px;
}

h5 {
  margin-bottom: 20px;
}

.footer_class {
  width: 100%;
  height: 100px;
  background-color: #303030;
  color: white;
}

.login_button {
  width: 80px;
  height: 25px;
  line-height: 7px;
}

.login_button a {
  color: #409EFF;
}

.login_button:hover a {
  color: #ffffff;
}
</style>
