<template>
  <div>
    <nav-header content=""></nav-header>
    <div class="main_div">
      <div v-for="(value, key) in payImage " :key="key">
        <img :src="getImgUrl(value)"  alt="" style="height: 300px;"/>
      </div>
    </div>
    <nav-footer class="footer_class"></nav-footer>
  </div>
</template>
<script>
import NavHeader from '../common/NavHeader.vue'
import NavFooter from '../common/NavFooter.vue'
export default {
  components:{
    NavHeader,
    NavFooter
  },
  data() {
    return {
      dialogVisible:false,
      labelPosition: 'right',
      showMenu:false,
      payImage:[]
    }
  },
  created() {
    this.getCardDetail()
  },
  methods:{
    getImgUrl (img) {
      img=img.replace(new RegExp(",","g"),"")
      return process.env.VUE_APP_URL+"/v1/commodityManage/previewImage?path="+img;
    },
    getCardDetail(){
      this.axios({
        method:"get",
        url:"/v1/sysConfig/selectConfig"
      }).then((res)=>{
        const inputData=res.data.data.list;
        for(let i=0;i<inputData.length;i++){
          let data=inputData[i];
          if(data.key==='pay_image'){
            if(data.value !== undefined && data.value !== null){
              this.payImage=data.value.split(",");
            }
          }
        }
      })
    },
  }
}
</script>
<style scoped>
/deep/.el-input__inner{
  border-color: #abadb1;
}
.login_from_class{
  width: 500px;
  margin: auto;
  z-index:-1;
}
.loginInput .el-form-item {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 20px;
}
.registerInput .el-form-item {
  padding-right: 20px;
  margin-bottom: 15px;
}
.input-width{
  width: 300px
}
element.style{
  margin-left: 0;
}
.main_div{
  height: 750px;padding-top: 50px;overflow: auto
}
.footer_class{
  width: 100%;height: 100px;background-color: #303030;color:white
}
</style>
