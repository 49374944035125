<template>
  <div>
    <nav-header @getChildValue="getChildValue" :showMenu="showMenu" content=""></nav-header>
    <div style="width: 1200px;margin: auto;height: 80vh">
      <h2>{{menuName}}</h2>
      <div class="ul_div">
        <ul>
          <li @click="toDetail(commodity.id)" v-for="(commodity, key) in commodityList " :key="key" class="hover_style">
              <div class="nr_div">
                <img style="height: 215px;width:100%"  :src="getImgUrl (commodity.imageUrl)">
                <div style="text-align: left;">
                  <h3>{{commodity.code}}</h3>
                  <div v-show="showPrice===1">
                    <h4 style="color: red" v-if="$i18n.locale === 'zh_CN'">￥{{commodity.mackPriceRmb}}</h4>
                    <h4 style="color: red" v-else-if="$i18n.locale === 'en_US'">${{commodity.mackPriceUsd}}</h4>
                  </div>
                </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div style="float: left;width: 100%">
      <h4 @click="getMore()">{{$t('m.seeMore')}}</h4>
      <nav-footer class="footer_class"></nav-footer>
    </div>
  </div>
</template>

<script>
  import NavHeader from '../common/NavHeader.vue'
  import NavFooter from '../common/NavFooter.vue'
  export default {
    components:{
      NavHeader,
      NavFooter
    },
  data() {
    return {
      menuName:this.$route.query.topType.menuName,
      showPrice:this.$route.query.topType.showPrice,
      showMenu:false,
      searchRadio:1,
      commodityList:[],
      checkboxGroup:[],
      menus: [],
      params:{
        lag:this.$i18n.locale,
        page:1,
        size:10,
      }
    }
  },
  created() {
    this.setDefaultData();
  },
  methods:{
    getMore(){
      this.params.page+=1;
      this.selectCommodityList();
    },
    setDefaultData(){
      this.selectCommodityList();
    },
    changeType(val){
      this.checkboxGroup=val
      this.params.codeType= val.join(",")
      this.selectCommodityList();
    },
    getImgUrl (img) {
      return process.env.VUE_APP_URL+"/v1/commodityManage/previewImage?path="+img.split(",")[0];
    },
    toDetail(id){
      let routeData = this.$router.resolve({path: '/detail', query: {id:id}});
      window.open(routeData.href,'_blank');
    },
    selectCommodityList(){
      this.axios({
        method:"get",
        url:"/v1/front/manage/queryCommodityManageList",
        params:this.params
      }).then((res)=>{
        for(let i=0;i<res.data.data.list.length;i++){
          this.commodityList.push(res.data.data.list[i])
        }
      })
    },
    getChildValue(){
      location.reload();
    }
  }
}
</script>

<style scoped>
  .ul_div{
    float: left;padding-left: 5px;width: 100%
  }
  .nr_div{
    float: left;height: 300px;width: 100%
  }
  .hover_style{
    float: left;margin: 10px 4px;width: 19%;border: #eee solid 1px;height: 300px
  }
.hover_style:hover>div{
  cursor:pointer;
  border: rgb(35, 172, 255) solid 3px
}

.type_hover_style:hover{
  cursor:pointer;
  color: rgb(35, 172, 255);
  border: rgb(35, 172, 255) solid 1px
}

.el-checkbox{
  margin-right: 0;
}

h3,h4{
  font-weight:normal;
  margin: 8px 10px 0 10px;
}
h4:hover{
  cursor: pointer;
}
.footer_class{
  width: 100%;height: 100px;position: relative;bottom: 0;background-color: #303030;color:white
}
</style>
