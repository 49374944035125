<template>
  <div>
    <nav-header content=""></nav-header>
    <div class="div1">
      <el-tabs type="border-card" class="login_from_class">
        <el-tab-pane :label="$t('m.register')">
          <el-form :label-position="labelPosition" label-width="100px" :rules="rules2" ref="ruleForm2" :model="ruleForm2" class="registerInput">
            <el-form-item :label="$t('m.accountNumber')" prop="account">
              <el-input  v-model="ruleForm2.account" :placeholder="$t('m.accountNumber')" size="mini" maxlength="20" s show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.password')" prop="password">
              <el-input   v-model="ruleForm2.password" :placeholder="$t('m.password')" size="mini" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.fullName')" >
              <el-input  v-model="ruleForm2.nickName" :placeholder="$t('m.fullName')" size="mini" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.mobilePhone')" prop="phone">
              <el-input  v-model="ruleForm2.phone" :placeholder="$t('m.mobilePhone')" size="mini" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.email')">
              <el-input  v-model="ruleForm2.email" placeholder="email" size="mini" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="QQ">
              <el-input  v-model="ruleForm2.qqNum" placeholder="QQ" size="mini" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.instantMessaging')">
              <el-input  v-model="ruleForm2.wxNum" :placeholder="$t('m.instantMessaging')" size="mini" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.otherContactInformation')">
              <el-input  v-model="ruleForm2.gogleNum" :placeholder="$t('m.otherContactInformation')" size="mini" maxlength="100" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.address')">
              <el-input  v-model="ruleForm2.address" :placeholder="$t('m.address')" size="mini" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.gender')" style="text-align: left">
              <el-radio v-model="ruleForm2.gender" label=1>{{$t('m.male')}}</el-radio>
              <el-radio v-model="ruleForm2.gender" label=2>{{ $t('m.female') }}</el-radio>
            </el-form-item>
            <el-form-item  :label="$t('m.verificationCode')"  prop="code">
              <el-col :span="15">
                <el-input  v-model="ruleForm2.code"  :placeholder="$t('m.verificationCode')" maxlength="5" autocomplete="off" @keyup.enter.native="registerSubmit('ruleForm2')" size="mini"></el-input>
              </el-col>
              <el-col :span="9">
                <img :src="ruleForm2.imageSrc" @click="getCode" class="img_class"/>
              </el-col>
            </el-form-item>
            <el-form-item label-width="0" class="form_div">
              <el-button type="primary" @click="registerSubmit('ruleForm2')">{{$t('m.register')}}</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('m.signIn')">
          <el-form :rules="rules1" ref="ruleForm1" :model="ruleForm1" class="loginInput">
            <el-form-item prop="uname">
              <el-input v-model="ruleForm1.uname" :placeholder="$t('m.userName')" maxlength="20" show-word-limit></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="ruleForm1.password" :placeholder="$t('m.password')" show-password maxlength="20"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-col :span="15">
                <el-input class="input_style" v-model="ruleForm1.code"  :placeholder="$t('m.verificationCode')" maxlength="5" autocomplete="off" @keyup.enter.native="loginSubmit('ruleForm1')"></el-input>
              </el-col>
              <el-col :span="9">
                <img :src="ruleForm1.imageSrc" @click="getCode" class="img_class2"/>
              </el-col>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="loginSubmit('ruleForm1')">{{$t('m.signIn')}}</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
<!--    <div style="float: left;;width: 100%">
      <div style="height: 70px"></div>
      <nav-footer style="height: 100px;background-color: #303030;color:white"></nav-footer>
    </div>-->
    <nav-footer class="footer_class"></nav-footer>
  </div>
</template>
<script>
  import NavHeader from '../common/NavHeader.vue'
  import NavFooter from '../common/NavFooter.vue'
  import  {uuid}  from 'vue-uuid';
  let uuidR;
  export default {
    components:{
      NavHeader,
      NavFooter
    },
    data() {
      return {
        labelPosition: 'right',
        showMenu:false,
        ruleForm1:{
          uname:'',
          password:'',
          imageSrc:'',
          code:'',
        },
        ruleForm2:{
          uname:'',
          password:'',
          imageSrc:'',
          code:'',
          account:'',
          nickName:'',
          phone:"",
          qqNum:"",
          wxNum:"",
          address:"",
          email:"",
          corporateName:"",
          gender:"",
          gogleNum:"",
          clientUid:""
        },
        rules1: {
          uname: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ],
          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ],
        },
        rules2: {
          uname: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ],
          code: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ],
          account: [
            { required: true, message: '请输入账号', trigger: 'blur' }
          ],
          /*nickName: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],*/
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' }
          ],
        }
      }
    },
    created() {
      this.getCode();
      this.deleteUserName()
    },
    methods:{
      deleteUserName(){
        localStorage.removeItem("userName")
      },
      getCode(){
        uuidR = uuid.v1();
        this.ruleForm1.imageSrc=process.env.VUE_APP_URL+"/v1/webUser/getVerification?clientUid="+uuidR
        this.ruleForm2.imageSrc=process.env.VUE_APP_URL+"/v1/webUser/getVerification?clientUid="+uuidR
      },
      loginSubmit(formName){
        this.$refs[formName].validate((valid) => {
          if (!valid) {
            return false;
          } else {
            this.axios({
              method:"post",
              url:"/v1/front/user/login",
              data:{
                account:this.ruleForm1.uname,
                clientUid:uuidR,
                password:this.ruleForm1.password,
                code:this.ruleForm1.code
              }
            }).then((res)=> {
              if (res.data.code === 200) {
                localStorage.setItem("token", res.headers.token)
                localStorage.setItem("userName", res.data.data.userName)
                localStorage.setItem("roleNum", res.data.data.roleNum)
                this.$router.push('/');
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                });
              }
            })
          }
        })
      },
      registerSubmit(formName){
        this.ruleForm2.clientUid=uuidR
        this.$refs[formName].validate((valid) => {
          if (!valid) {
            return false;
          } else {
            this.axios({
              method: "post",
              url: "/v1/front/user/register",
              data: this.ruleForm2
            }).then((res) => {
              if (res.data.code === 200) {
                this.$confirm(this.$t('m.registrationSucceededToLogin'), this.$t('m.loginWasSuccessful'), {
                  confirmButtonText: this.$t('m.determine'),
                  cancelButtonText: this.$t('m.cancel'),
                  type: 'success'
                }).then(() => {
                  this.$router.push('/login');
                })
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.message
                });
              }
            })
          }
        })
      }
    }
  }
</script>
<style scoped>
/deep/.el-input__inner{
  border-color: #abadb1;
}
  .login_from_class{
    width: 500px;
    margin: auto;
    z-index:-1;
  }
  .loginInput .el-form-item {
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 20px;
  }
  .registerInput .el-form-item {
    padding-right: 20px;
    margin-bottom: 15px;
  }
  .input-width{
    width: 300px
  }
  element.style{
    margin-left: 0;
  }
.div1{
  height: 750px;padding-top: 50px;overflow: auto
}
.img_class{
  width: 65%;height:30px;margin-left: 8px;
}
.img_class2{
  width: 90%;height:28px;margin-left: 8px;
}
.form_div{
  text-align: center;margin-top: 20px
}
.footer_class{
  width: 100%;height: 100px;background-color: #303030;color:white
}
</style>
