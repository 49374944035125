export const m = {
  language: "Language",
  chinese: "Chinese",
  english: "English",
  shoppingCart: "Shopping Cart",
  personalCenter: "Personal Center",
  signIn: "Sign in",
  register: "Register",
  search: "Search",
  advancedSearch: "advanced Search",
  pleaseContent: "Product name/Product Description/Battery description",
  helloWelcome: "Hello, welcome to the mall!",
  allClassification: "All classification",
  homePage: "Home page",
  hotProject: "Hot Project",
  inventoryProcessing: "Inventory Processing",
  groupProduct: "Group Product",
  aquaticProduct: "Aquatic Product",
  spotDirectSales: "Spot Direct Sales",
  desktopMobileGameToys: "Desktop Mobile Game Toys",
  babyToys: "Baby Toys",
  notPrice: "No price",
  messageCenter: "Message center",
  commodityInformation: "Commodity information",
  unitPrice: "Unit Price",
  price: "Price",
  quantity: "Quantity",
  money: "Amount of money",
  totalAmount: "Total amount",
  operation: "Operation",
  placeOrder: "Place order",
  confirmOrder: "Confirm order",
  totalOrderAmount: "The total order amount is",
  address: "Address",
  determine: "Confirm",
  cancel: "Cancel",
  fullName: "Full name",
  mobilePhone: "Mobile phone",
  orderManagement: "Order management",
  addressManagement: "Address management",
  safeExit: "Safe exit",
  date: "Date",
  edit: "Edit",
  delete: "Delete",
  areYouDelete: "Are you sure to delete?",
  addAddress: "Add receiving address",
  receivingAddress: "Receiving address",
  orderNumber: "Order number",
  accountNumber: "Account number",
  state: "State",
  submitTotalPrice: "Total price",
  finalOffer: "Final offer",
  currencyType: "Currency type",
  submissionTime: "Submission time",
  submitted: "Submitted",
  confirmed: "Confirmed",
  completed: "Completed",
  RMB: "RMB",
  dollar: "Dollar",
  productName: "Product name",
  productDescribe: "Product description",
  batteryDescribe: "Battery description",
  electric: "Electrified",
  packing: "Packing",
  innerBox: "Inner box",
  productSize: "Product size",
  outerBoxSize: "Carton size",
  volume: "volume",
  packingQuantity: "Packing quantity",
  grossWeight: "Gross/weight",
  netWeight: "Net weight",
  // nothing: "Not have",
  nothing: "",
  addToCart: "Add to cart",
  productDetails: "Product details",
  verificationCode: "Verification Code",
  seeMore: "See more",
  more: "More",
  userName: "User name",
  password: "password",
  otherContactInformation: "Other contact information",
  classification: "Classification",
  comprehensive: "Comprehensive",
  newest: "Newest",
  areYouSureToExit: "Are you sure to exit",
  areYouSureToChangeThePassword: "Are you sure to change the password",
  tips: "Tips",
  userInformation: "User information",
  changePassword: "Change Password",
  oldPassword: "Old password",
  newPassword: "New Password",
  registrationSucceededToLogin:
    "Registration succeeded. Do you want to jump to the login page?",
  loginWasSuccessful: "login was successful",
  instantMessaging: "instant messaging",
  gender: "Gender",
  male: "male",
  female: "female",
  email: "email",
  customer: "customer",
  customerPhone: "customer Phone",
  confirmPrice: "Confirm price",
  recommend: "Recommend",
  packQuantity: "Box quantity",
  screen: "screen",
  primaryClassification: "Primary Classification",
  secondaryClassification: "Secondary Classification",
  threeLevelClassification: "Three Level Classification",
  collect: "collect",
  operate: "operate",
  payment: "payment",
  code: "code",
  remarks: "remarks",
  serviceQQ: "service QQ",
  page1: 'name',
  page2: 'bar code',
  page3: 'package',
  page4: 'price',
  page5: 'Packing',
  page6: 'is Image',
  page7: 'long',
  page8: 'wide',
  page9: 'tall',
  page10: 'date',
  page11: 'please input',
  page12:'Start date',
  page13:'End Date',
  span1:'Please enter the product name',
  span2:'Please enter barcode',
  span3:'Please enter the packaging',
  span4:'Minimum price',
  span5:'Maximum price',
  span6:'Minimum Quantity',
  span7:'maximum number',
  span8:'Please select',
  span9:'Length cm',
  span10:'Width cm',
  span11:'Height cm',
};
