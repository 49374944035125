<template>
  <div>
    <nav-header :showMenu="showMenu" content="" @changeMeauIndex="changeMeauIndex"
                @switchSeach="switchSeach"></nav-header>
    <div style="width: 1200px; margin: auto">
      <el-scrollbar style="height: 100%">
        <div style="min-height: 608px;">
          <el-table
            v-infinite-scroll="load"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="code" :label="$t('m.code')">
            </el-table-column>
            <el-table-column
              prop="commodityName"
              :label="$t('m.commodityInformation')"
              width="100"
            >
              <template slot-scope="scope">
                <img
                  style="width: 80px; height: 80px"
                  :src="getImgUrl(scope.row.imageUrl)"
                />
              </template>
            </el-table-column>
            <el-table-column :label="$t('m.productName')">
              <template slot-scope="scope">
                <span v-if="$i18n.locale === 'zh_CN'">
                  {{ scope.row.name.zh_CN }}
                </span>
                <span v-if="$i18n.locale === 'en_US'">
                  {{ scope.row.name.en_US }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="describe" :label="$t('m.productDescribe')" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="packing" :label="$t('m.packing')">
            </el-table-column>
            <!-- <el-table-column :label="$t('m.unitPrice')" width="120">
              <template slot-scope="scope">
                <span v-if="$i18n.locale === 'zh_CN'">
                  <span v-if="scope.row.mackPriceRmb !== null">
                    ￥{{ scope.row.mackPriceRmb }}
                  </span>
                  <span v-else>
                    {{ $t("m.notPrice") }}
                  </span>
                </span>
                <span v-if="$i18n.locale === 'en_US'">
                  <span v-if="scope.row.mackPriceUsd !== null">
                    ￥{{ scope.row.mackPriceUsd }}
                  </span>
                  <span v-else>
                    {{ $t("m.notPrice") }}
                  </span>
                </span>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="count"
              :label="$t('m.quantity')"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-input-number
                  size="mini"
                  v-model="scope.row.count"
                  @change="handleChange(scope.row)"
                  :min="1"
                  :max="99999"
                ></el-input-number>
              </template>
            </el-table-column>
            <!-- <el-table-column :label="$t('m.money')" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="$i18n.locale === 'zh_CN'"
                  >￥{{ scope.row.mackPriceRmb * scope.row.count }}</span
                >
                <span v-if="$i18n.locale === 'en_US'"
                  >${{ scope.row.mackPriceUsd * scope.row.count }}</span
                >
              </template>
            </el-table-column> -->
            <!--          <el-table-column
              prop="address"
              :label="$t('m.operation')"
              show-overflow-tooltip>
          </el-table-column>-->
            <el-table-column :label="$t('m.operate')" width="200">
              <template slot-scope="scope">
                <el-button type="text" @click.stop="rowClick(scope.row)">{{ $t("m.productDetails") }}</el-button>
                <el-button type="text" @click.stop="del(scope.row)">{{ $t("m.delete") }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <el-pagination
          background
          layout="prev, pager, next"
          hide-on-single-page
          :page-count="totalPage"
          :current-page="params.page"
          @current-change="changePage"
        >
        </el-pagination> -->
      </el-scrollbar>

      <div class="div1">
        <div class="div2">
          <!-- <div class="div3">
            <span
              >{{ $t("m.totalAmount") }}:&nbsp;&nbsp;
              <span v-if="$i18n.locale === 'zh_CN'">￥ {{ totalPrice }}</span>
              <span v-if="$i18n.locale === 'en_US'">$ {{ totalPrice }}</span>
            </span>
          </div> -->
        </div>
        <div class="submit_style" @click="placeOrder">
          <span>{{ $t("m.placeOrder") }}</span>
        </div>
      </div>
      <el-dialog
        :title="$t('m.confirmOrder')"
        :visible.sync="dialogFormVisible"
      >

        <el-form>
          <el-form-item
            :label="$t('m.remarks')"
            label-width="50px"
          >
              <el-input
              v-model="mark"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
        </el-form>
        <hr />
        <!-- <div class="div4">
          {{ $t("m.totalOrderAmount") }}:
          <span v-if="$i18n.locale === 'zh_CN'">￥ {{ totalPrice }}</span>
          <span v-if="$i18n.locale === 'en_US'">$ {{ totalPrice }}</span>
        </div> -->
        <div class="div5">
          {{ $t("m.address") }}:
          <el-button type="primary" @click="showAdd">{{
            $t("m.addAddress")
          }}</el-button>
          <el-table
            :data="addressTableData"
            style="width: 100%"
            @row-click="singleElection"
          >
            <el-table-column label="" width="65">
              <template slot-scope="scope">
                <el-radio
                  class="radio"
                  v-model="templateSelection"
                  :label="scope.$index"
                  >&nbsp;</el-radio
                >
              </template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('m.fullName')">
            </el-table-column>
            <el-table-column prop="phone" :label="$t('m.mobilePhone')">
            </el-table-column>
            <el-table-column
              prop="address"
              :label="$t('m.address')"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column :label="$t('m.operation')">
              <template slot-scope="scope">
                <el-row>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('m.edit')"
                    placement="top-end"
                  >
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      circle
                      size="mini"
                      @click="edit(scope.$index, scope.row)"
                      style="margin-right: 5px; margin-left: 0px"
                    ></el-button>
                  </el-tooltip>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            :page-size="siteParams.size"
            layout="total,prev, pager, next"
            @current-change="handleCurrentChange3"
            :total="siteTotal"
            style="margin-top: 8px;text-align: right;"
            :hide-on-single-page="true"
          >
          </el-pagination>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">{{
            $t("m.cancel")
          }}</el-button>
          <el-button type="primary" @click="subMitOrder">{{
            $t("m.determine")
          }}</el-button>
        </div>
      </el-dialog>
      <!-- 新增地址 -->
      <el-dialog
          :title="$t('m.receivingAddress')"
          :visible.sync="dialogFormVisibleAdd"
          width="500px;"
        >
          <el-form :model="form">
            <el-form-item
              :label="$t('m.fullName')"
              label-width="100px"
            >
              <el-input
                v-model="form.name"
                autocomplete="off"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('m.mobilePhone')"
              label-width="100px"
            >
              <el-input
                v-model="form.phone"
                autocomplete="off"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('m.address')"
              label-width="100px"
            >
              <el-input
                v-model="form.address"
                autocomplete="off"
                style="width: 300px"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisibleAdd = false">{{
              $t("m.cancel")
            }}</el-button>
            <el-button type="primary" @click="addUser">{{
              $t("m.determine")
            }}</el-button>
          </div>
        </el-dialog>
    </div>
    <div style="float: left; width: 100%">
      <div style="height: 17px"></div>
      <nav-footer class="footer_class"></nav-footer>
    </div>
  </div>
</template>

<script>
import NavHeader from "../common/NavHeader.vue";
import NavFooter from "../common/NavFooter.vue";
export default {
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      showMenu: false,
      tableData: [],
      totalPrice: 0,
      dialogFormVisible: false,
      addressTableData: [],
      // 地址分页
      siteTotal: 0,
      siteParams: {
        page: 1,
        size: 5,
      },
      templateSelection: "",
      templateRadio: "",
      totalPage: 1,
      params: {
        page: 1,
        size: 10,
        lag: this.$i18n.locale,
      },
      multipleSelection: [],
      form: {
        name: "",
        phone: "",
        address: "",
        id: "",
      },
      dialogFormVisibleAdd: false,
      mark:"",
    };
  },
  created() {
    // this.getCartList();
    this.queryAddressList();
  },
  methods: {
    handleChange(row) {
      // console.log(row);
      this.axios({
        method: "get",
        url: "/v1/shoppingCart/update",
        params: {
          cartid:row.id,
          count:row.count,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: this.$i18n.locale == "zh_CN" ? "修改成功" : "Modified successfully",
            type: "success",
          });
        }
      });
    },
    placeOrder() {
      if(this.multipleSelection.length<=0){
        this.$message({
          type: "error",
          message: this.$i18n.locale == "zh_CN" ? "请先勾选商品" : "Please check the item first",
        });
        return false;
      }
      this.mark = "";
      this.dialogFormVisible = true
    },
    showAdd() {
      this.dialogFormVisibleAdd = true;
      this.form.name = "";
      this.form.phone = "";
      this.form.address = "";
      this.form.id = "";
    },
    edit(index, row) {
      this.dialogFormVisibleAdd = true;
      this.form.name = row.name;
      this.form.phone = row.phone;
      this.form.address = row.address;
      this.form.id = row.id;
    },
    addUser() {
      this.dialogFormVisibleAdd = false;
      this.axios({
        method: "post",
        url: "/v1/front/address/addAddress",
        data: this.form,
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: this.$i18n.locale == "zh_CN" ? "上传成功" : "Upload successfully",
            type: "success",
          });
          this.queryAddressList();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    // 删除
    del(row) {
      this.axios({
        url: "/v1/shoppingCart/del/"+row.id,
        method: "get",
      }).then((res) => {
        // console.log(res, "删除购物车");
        if (res.data.code == 200) {
          let message =
              this.$i18n.locale == "zh_CN" ? "删除成功" : "Successfully Delete";
            this.$message({
              message: message,
              type: "info",
            });
          this.params.page == 1;
          this.tableData = [];
          this.getCartList();
        }
      });
    },
    load() {
      if (this.params.page <= this.totalPage) {
        this.params.page++;
        this.getCartList();
      }
    },
    rowClick(row) {
      this.$router.push({ path: "/detail", query: { id: row.commodityId } });
    },
    subMitOrder() {
      let flag = true;
      if (flag) {
        flag = false;
        const submitCurrencyType = this.$i18n.locale === "zh_CN" ? 0 : 1;
        if (this.templateRadio === undefined || this.templateRadio === "") {
          this.$message({
            type: "error",
            message: this.$i18n.locale == "zh_CN" ? "请选择收货地址" : "Please select a delivery address",
          });
        }
        this.axios({
          method: "post",
          url: "/v1/front/order/createOrderv1",
          data: JSON.stringify({
            cartIds: this.multipleSelection,
            addressId: this.templateRadio,
            submitCurrencyType: submitCurrencyType,
            mark: this.mark,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          if (res.data.code === 200) {
            flag = true;
            let message =
              this.$i18n.locale == "zh_CN" ? "提交成功" : "Submit Successfully";
            this.$message({
              type: "success",
              message: message,
            });
            this.dialogFormVisible = false;
            this.$router.push({ path: "/personal", query: { name: "1" } });
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      } else {
        this.$message({
          type: "error",
          message: this.$i18n.locale == "zh_CN" ? "请勿重复提交" : "Do not repeat submission",
        });
      }
    },
    getImgUrl(img) {
      if (img !== null && img !== undefined) {
        let imgs = img.split(",");
        if (imgs.length > 0) {
          return (
            process.env.VUE_APP_URL+"/v1/commodityManage/previewImage?path=" + img.split(",")[0]
          );
        }
      }
      return "";
    },
    getCartList() {
      this.axios({
        method: "get",
        url: "/v1/shoppingCart/querySelfList",
        params: this.params,
      }).then((res) => {
        if (res.data.code === 200) {
          // console.log(res, "购物车");
          let list = res.data.data.list;
          for (let i = 0; i < list.length; i++) {
            list[i].name=JSON.parse(list[i].name);
          }
          this.tableData = this.tableData.concat(list);
          this.totalPage = Math.ceil(res.data.data.total / 10);
        }
      });
    },
    // 当前页改变
    changePage(val) {
      // console.log(val, "当前页改变");
      this.params.page = val;
      this.getCartList();
    },
    handleSelectionChange(val) {
      const ids = [];
      let totalPrice = 0;
      for (let i = 0; i < val.length; i++) {
        if (this.$i18n.locale === "zh_CN") {
          totalPrice += val[i].mackPriceRmb * val[i].count;
        } else {
          totalPrice += val[i].mackPriceUsd * val[i].count;
        }
        ids.push(val[i].id);
      }
      this.totalPrice = totalPrice;
      this.multipleSelection = ids;
    },
    queryAddressList() {
      this.axios({
        method: "get",
        url: "/v1/front/address/queryAddressList",
        params: this.siteParams,
      }).then((res) => {
        if (res.data.code === 200) {
          // console.log(res, "res");
          this.addressTableData = res.data.data.list;
          this.siteTotal = res.data.data.total;
        }
      });
    },
    handleCurrentChange3(val) {
      this.siteParams.page = val;
      this.queryAddressList();
    },
    singleElection(row) {
      this.templateSelection = this.addressTableData.indexOf(row);
      this.templateRadio = row.id;
    },
    changeMeauIndex(id, content) {
      this.meauIndex = id;
      this.content = content;
    },
    switchSeach(codeType, id) {
      console.log("触发了")
      // this.showSelectCard = !this.showSelectCard;
      this.isShowSeach = !this.isShowSeach;
      this.$router.push({
        path: "/searchNew",
        query: {
          codeType: codeType,
          classId: id ? id : null,
          meauIndex: "searchNew",
        },
      });
    },
  },
};
</script>

<style scoped>
.submit_style {
  float: right;
  width: 15%;
  height: 50px;
  background-color: rgba(35, 172, 255, 0.54);
  color: rgb(0, 90, 144);
  line-height: 50px;
  font-size: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit_style:hover {
  cursor: pointer;
  background-color: rgba(35, 172, 255, 0.89);
}
body .el-scrollbar__wrap {
  overflow-x: hidden;
}
.div1 {
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.div2 {
  float: left;
  width: 85%;
  height: 50px;
  background-color: #eee;
}
.div3 {
  float: right;
  margin-right: 20px;
  font-size: 20px;
  color: #ee0a24;
}
.div4 {
  margin-right: 20px;
  font-size: 20px;
  color: #ee0a24;
  text-align: left;
  margin-top: 20px;
}
.div5 {
  margin-top: 15px;
  text-align: left;
}
.footer_class {
  width: 100%;
  height: 100px;
  position: relative;
  bottom: 0;
  background-color: #303030;
  color: white;
}
</style>
