<template>
    <footer>
        <div class="foot">
            <p class="foot_info" v-for="item in footList" :key="item.id">{{item.name}}：{{item.value}}</p>
            <p class="foot_url">
                <a v-for="item in footUrlList" :key="item.id" :href="item.value" target="_blank">{{ item.name }}</a>
            </p>
            <p class="foot_url">
                <a v-for="item in footPutList" :key="item.id" :href="item.value" target="_blank">{{ item.name }}</a>
            </p>
        </div>
        
      

<!--        Toy shop v1.0.0 | ICP备案 沪ICP备xxx号 |
            <img src="../../assets/img/beian-gongan-icon.png">公安备案 京公网安备xxx号 |
            <img src="../../assets/img/beian-company-license-icon.png">电子营业执照亮照 电子营业执照亮照 | 沪B2-xxx</p>-->
    </footer>
</template>
<script>
export default{
    data() {
        return {
            footList: [],
            footUrlList: [],
            footPutList: [],
        }
    },
    created() {
        this.getCardDetail();
        this.getfootUrl();
        this.getputUrl();
    },
    methods:{
        getCardDetail(){
            this.axios({
                method:"get",
                url:"/v1/config/list",
                params: {
                    lag: this.$i18n.locale == "zh_CN" ? true : false,
                    postion: 'foot',
                }
            }).then((res)=>{
                // console.log('底部', res);
                this.footList=res.data.data.list;
            })
        },
        getfootUrl(){
            this.axios({
                method:"get",
                url:"/v1/config/list",
                params: {
                    lag: this.$i18n.locale == "zh_CN" ? true : false,
                    postion: 'hotUrl',
                }
            }).then((res)=>{
                // console.log('底部链接', res);
                this.footUrlList=res.data.data.list;
            })
        },
        getputUrl(){
            this.axios({
                method:"get",
                url:"/v1/config/list",
                params: {
                    lag: this.$i18n.locale == "zh_CN" ? true : false,
                    postion: 'footUrl',
                }
            }).then((res)=>{
                // console.log('底部备案', res);
                this.footPutList=res.data.data.list;
            })
        },
    }
}
</script>

<style scoped>
    .foot {
        width: 100%;
        min-height: 100px;
        position: relative;
        bottom: 0;
        background-color: #303030;
        color: white;
        padding: 20px 0;
    }
    .foot p{
        font-size: 12px;
        text-align: center;
        padding: 10px 40px;
        margin: 0;
        box-sizing: border-box;
    }
    .foot p a {
        color: white;
    }
    .foot_info {
        width: 100%;
    }
    .foot_url {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .foot_url a {
        margin:  10px;
    }
</style>
