<template>
  <div class="MainContent">
    <!-- 最新 -->
    <div class="commodity_div">
      <div style="width: 100%;">
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <span style="font-size: 20px; display: block">
            {{ $t("m.newest") }}
          </span>
          <a class="clickStyle" @click="toSearchNew()" style="font-size: 16px; float: right">{{ $t("m.more") }}</a>
        </div>
      </div>
      <el-container class="commodity_list_div">
        <el-main style="padding: 0;border:0;display: flex;flex-wrap: wrap;justify-content: space-between;">
          <el-card shadow="hover" class="goods-items" style="width: 187px;" :body-style="{ padding: '5px' }"
            v-for="(value, i) in newList" :key="i">
            <div @click.stop="toDetail(value.id, false)">
              <img style="height: 170px; width: 100%" :src="getImgUrl(value.imageUrl)" />
              <div style="text-align: left">
                <div class="commodity_Code" style="overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;">
                  {{ value.name }}
                </div>
                <div class="commodity_Code">
                  {{ value.code }}
                </div>
                <div style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                ">
                  <img style="width: 16px; height: 14px" src="../../assets/img/collect_active.png" alt=""
                    v-if="value.favor" @click.stop="changeLike(value)" />
                  <img style="width: 16px; height: 14px" src="../../assets/img/collect.png" alt=""
                    @click.stop="changeLike(value)" v-else />
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16"
                    height="16" style="margin-left: 8px;" @click.stop="changeLikeShop(value)" viewBox="0 0 24 24">
                    <path
                      d="M11 9h2V6h3V4h-3V1h-2v3H8v2h3v3zm-4 9c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2s-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2s2-.9 2-2s-.9-2-2-2zm-8.9-5h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L19.42 4l-3.87 7H8.53L4.27 2H1v2h2l3.6 7.59l-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2z"
                      fill="currentColor"></path>
                  </svg>
                </div>
              </div>
            </div>

          </el-card>
        </el-main>
      </el-container>
    </div>

    <!-- 板块 -->
    <div v-for="(item, index) in currentBand" :key="index" class="commodity_div">
      <div style="width: 100%;">
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          ">
          <span style="font-size: 20px; display: block" v-if="$i18n.locale == 'zh_CN'">
            {{ item.bandNameZhcn }}
          </span>
          <span style="font-size: 20px; display: block" v-if="$i18n.locale == 'en_US'">
            {{ item.bandNameEnus }}
          </span>
          <a class="clickStyle" @click="toSearchPage(item.id)" style="font-size: 16px; float: right">{{ $t("m.more")
            }}</a>
        </div>

        <span style="font-size: 20px; display: block" v-if="$i18n.locale == 'zh_CN'">
          {{ item.contentZhcn }}
        </span>
        <span style="font-size: 20px; display: block" v-if="$i18n.locale == 'en_US'">
          {{ item.contentEnus }}
        </span>
      </div>
      <el-container class="commodity_list_div">
        <el-aside class="aside_class" style="width: 200px;">
          <img :src="getImgUrl(item.bgimg)" alt="是个图片" />
        </el-aside>
        <el-main style="padding: 0;overflow: hidden;display: flex;flex-wrap: wrap;justify-content: flex-start;">
          <el-card shadow="hover" class="goods-items" :body-style="{ padding: '5px' }"
            v-for="(value, i) in orderList[index]" :key="i">
            <div @click.stop="toDetail(value.id, item.priceDisplay)">
              <img style="height: 170px; width: 100%" :src="getImgUrl(value.imageUrl)" />
              <div style="text-align: left">
                <div class="commodity_Code" style="overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;">
                  {{ value.name }}
                </div>
                <div class="commodity_Code">
                  {{ value.code }}
                </div>
                <div v-if="item.priceDisplay">
                  <div class="commodity_Name" v-show="$i18n.locale == 'zh_CN'">
                    &yen;{{ value.mackPriceRmb }}
                  </div>
                  <div class="commodity_Name" v-show="$i18n.locale == 'en_US'">
                    {{ value.mackPriceUsd }}$
                  </div>
                </div>
                <div style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                ">
                  <img style="width: 16px; height: 14px" src="../../assets/img/collect_active.png" alt=""
                    v-if="value.favor" @click.stop="changeLike(value)" />
                  <img style="width: 16px; height: 14px" src="../../assets/img/collect.png" alt=""
                    @click.stop="changeLike(value)" v-else />
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16"
                       height="16" style="margin-left: 8px;" @click.stop="changeLikeShop(value)" viewBox="0 0 24 24">
                    <path
                        d="M11 9h2V6h3V4h-3V1h-2v3H8v2h3v3zm-4 9c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2s-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2s2-.9 2-2s-.9-2-2-2zm-8.9-5h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L19.42 4l-3.87 7H8.53L4.27 2H1v2h2l3.6 7.59l-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2z"
                        fill="currentColor"></path>
                  </svg>
                </div>
              </div>
            </div>
          </el-card>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
export default {
  name: "MainContent",
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el; // 获取当前元素
        oDiv.onmousedown = (e) => {
          // console.log("onmousedown");
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;

          document.onmousemove = (e) => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;
            if (left >= document.documentElement.clientWidth - 200) {
              left = document.documentElement.clientWidth - 200;
            }
            if (top <= 180) {
              top = 180;
            }
            oDiv.style.left = left + "px";
            oDiv.style.top = top + "px";
          };

          document.onmouseup = (e) => {
            console.log(e);
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  components: {},
  data() {
    return {
      logoImage: "",
      keyVxNo: "",
      keyQqNo: "",
      keyTelNo: "",
      keyWorkTime: "",
      uname: localStorage.getItem("userName"),
      showMenu: true,
      menus1: [],
      menus2: [],
      menus1_15: [],
      menus1_35: [],
      imageUrl: "/api/v1/infoManage/previewImage",
      infoList: [],
      homeImage: "",
      leftHomeImage1: "",
      leftHomeImage2: "",
      page: 1,
      currentBand: null,
      project: null,
      listArray: null,
      orderList: [],
      newList: [],
      //listArray:[{id:1,code:'asdfadfas'},{id:2,code:'asdfadfas'},{id:3,code:'asdfadfas'}]
    };
  },
  created() {
    let _this = this;
    axios.get("/v1/Bands/getband/" + this.page).then(function (rs) {
      // console.log('板块内容',rs)
      _this.currentBand = rs.data.data.list;
      _this.currentBand.forEach((e, index) => {
        // console.log(e);
        _this.getBandDetail(e.id, index);
      });
    });
  },
  mounted() {
    // this.selectInfoList();
    this.getNew();
    this.getMenusFirst();
    this.selectImageList();
    this.getCardDetail();
  },
  methods: {
    // 最新
    getNew() {
      this.axios({
        method: "get",
        url: "/v1/commodityManage/new",
        params: {
          lag: this.$i18n.locale,
        },
      }).then((res) => {
        // console.log('最新',res);
        this.newList = res.data.data.list;
      });
    },
    // 是否收藏
    changeLike(item) {
      this.axios({
        url: "/v1/favor/add",
        method: "get",
        params: {
          id: item.id,
        },
      }).then((res) => {
        // console.log(res, "收藏");
        if (res.data.code == 200) {
          if (res.data.data == "isnotFavor") {
            item.favor = false;
            let message =
              this.$i18n.locale == "zh_CN" ? "取消收藏" : "Cancel Favorite";
            this.$message({
              message: message,
              type: "info",
            });
          } else {
            item.favor = true;
            let message =
              this.$i18n.locale == "zh_CN" ? "收藏成功" : "Success Favorite";
            this.$message({
              message: message,
              type: "success",
            });
          }
        }
      });
    },
    // 加入购物车
    changeLikeShop(item) {
      console.log(item);
      this.axios({
        method: "post",
        url: "/v1/shoppingCart/addToCart",
        params: {
          commodityId: item.id,
          count: 1,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: "加入购物车成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });

      // this.axios({
      //   url: "/v1/favor/add",
      //   method: "get",
      //   params: {
      //     id: item.id,
      //   },
      // }).then((res) => {
      //   // console.log(res, "收藏");
      //   if (res.data.code == 200) {
      //     if (res.data.data == "isnotFavor") {
      //       item.favor = false;
      //       let message =
      //         this.$i18n.locale == "zh_CN" ? "移除购物车" : "Cancel Favorite";
      //       this.$message({
      //         message: message,
      //         type: "info",
      //       });
      //     } else {
      //       item.favor = true;
      //       let message =
      //         this.$i18n.locale == "zh_CN" ? "加入购物车成功" : "Success Favorite";
      //       this.$message({
      //         message: message,
      //         type: "success",
      //       });
      //     }
      //   }
      // });
    },
    getBandDetail(id, index) {
      var that = this;
      axios
        .get("/v1/Bands/getCommodity/" + id, {
          params: {
            lag: this.$i18n.locale,
          },
        })
        .then((res) => {
          // console.log(res.data.data.list, "res.data.data.list");
          //   res.data.data.list.map((item) => {
          //     item.name = JSON.parse(item.name);
          //     item.batteryDescribe = JSON.parse(item.batteryDescribe);
          //     item.describe = JSON.parse(item.describe);
          //   });
          that.$set(that.orderList, index, res.data.data.list);
        });
    },

    getCardDetail() {
      this.axios({
        method: "get",
        url: "/v1/sysConfig/selectConfig",
      }).then((res) => {
        const inputData = res.data.data.list;
        for (let i = 0; i < inputData.length; i++) {
          let data = inputData[i];
          if (data.key === "vx_no") {
            this.keyVxNo = data.value;
          }
          if (data.key === "qq_no") {
            this.keyQqNo = data.value;
          }
          if (data.key === "tel_no") {
            this.keyTelNo = data.value;
          }
          if (data.key === "work_time") {
            this.keyWorkTime = data.value;
          }
        }
      });
    },
    useInfo(value) {
      this.$router.push({ path: "/infoMessage", query: { value: value } });
    },
    toSearchPage(id) {
      this.$router.push({ path: "/search", query: { menuId: id } });
    },
    toSearchNew() {
      this.$router.push({ path: "/search", query: { new: true } });
    },
    strAnalysis(val) {
      return JSON.parse(val)[this.$i18n.locale];
    },
    selectInfoList() {
      /*this.axios({
        method:"get",
        url:"/v1/front/info/queryInfoList",
        params:{
          lag:this.$i18n.locale
        }
      }).then((res)=>{
        this.infoList=res.data.data.list
      })*/
    },
    selectImageList() {
      this.axios({
        method: "get",
        url: "/v1/imageConfig/selectImageConfig",
        params: {},
      }).then((res) => {
        for (let i = 0; i < res.data.data.list.length; i++) {
          //// console.log(res.data.data.list[i])
          if (res.data.data.list[i].positionNo === "1") {
            this.homeImage = res.data.data.list[i].imageUrl;
          }
          if (res.data.data.list[i].positionNo === "2") {
            this.leftHomeImage1 = res.data.data.list[i].imageUrl;
          }
          if (res.data.data.list[i].positionNo === "3") {
            this.leftHomeImage2 = res.data.data.list[i].imageUrl;
          }
          if (res.data.data.list[i].positionNo === "10") {
            this.logoImage = res.data.data.list[i].imageUrl;
          }
        }
      });
    },
    toDetail(id, priceDisplay) {
      // console.log(id);
      let routeData = this.$router.resolve({
        path: "/detail",
        query: { id: id, priceDisplay: priceDisplay },
      });
      window.open(routeData.href, "_blank");
    },
    getImgUrl(img) {
      if (img == null) return;
      img = img.replace(",", "");
      return process.env.VUE_APP_URL + "/v1/commodityManage/previewImage?path=" + img;
    },
    getMenusFirst() {
      this.axios({
        method: "get",
        url: "/v1/front/manage/getNewestAndRecomList",
        params: {
          lag: this.$i18n.locale,
          type: 2,
        },
      }).then((res) => {
        this.menus2 = res.data.data.list;
        // // console.log(this.menus)
      });
    },
    login() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    toPay() {
      let routeData = this.$router.resolve({ path: "/pay" });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
.commodity_Code:hover {
  white-space: normal !important;
}
.commodity_Code {
  margin: 0;
  margin-bottom: 4px;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
}

.commodity_Name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-bottom: 4px;
}

.commodity_div div span {
  display: block;
  width: 90%;
  text-align: left;
  margin: 0 0 10px;
}

.h_div {
  margin: 0;
  height: 2px;
  /* background-color: rgb(35, 172, 255); */
  width: 1015px;
}

.home_div img {
  width: 100%;
  height: 428px;
  object-fit: contain;
}

.commodity_div {
  margin: 50px auto 30px;
  width: 1220px;
  /* border-top: 3px solid red; */
  /* padding-top: 30px; */
}

.commodity_list_div {
  /* height: 738px; */
}

.aside_class {
  width: 200px;
}

.aside_class>img {
  display: block;
  width: 100%;
  /* height: 665px; */
  height: 100%;
  object-fit: inherit;
}

.goods-items {
  width: 19%;
  margin-bottom: 10px;
  float: left;
  /* margin-left: 11.5px; */
  cursor: pointer;
  margin-right: 0.8%;
}

.right_info img {
  margin-top: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.clickStyle:hover {
  cursor: pointer;
  color: rgb(35, 172, 255);
}

.h4_div li {
  line-height: 50px;
}

.el-main {
  border-top: 3px solid red;
}
</style>
