<template>
  <div>
    <!-- 头部 -->
    <NavHeader  :showMenu="showMenu" content="" @changeMeauIndex="changeMeauIndex"
                @switchSeach="switchSeach"></NavHeader>
    <!-- 列表 -->
    <div style="max-width: 1220px; margin: 0 auto">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column prop="code" :label="$t('m.code')">
        </el-table-column>
        <el-table-column
          prop="commodityName"
          :label="$t('m.commodityInformation')"
          width="100"
        >
          <template slot-scope="scope">
            <img
              style="width: 80px; height: 80px"
              :src="getImgUrl(scope.row.imageUrl)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('m.productName')">
        </el-table-column>
        <el-table-column prop="describe" :label="$t('m.productDescribe')" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="packing" :label="$t('m.packing')">
        </el-table-column>
        <el-table-column prop="ctn" :label="$t('m.packingQuantity')">
        </el-table-column>
        <el-table-column prop="innerBox" :label="$t('m.innerBox')">
        </el-table-column>
        <!-- <el-table-column :label="$t('m.unitPrice')" width="120">
          <template slot-scope="scope">
            <span v-if="$i18n.locale === 'zh_CN'">
              <span v-if="scope.row.mackPriceRmb !== null">
                ￥{{ scope.row.mackPriceRmb }}
              </span>
              <span v-else>
                {{ $t("m.notPrice") }}
              </span>
            </span>
            <span v-if="$i18n.locale === 'en_US'">
              <span v-if="scope.row.mackPriceUsd !== null">
                ${{ scope.row.mackPriceUsd }}
              </span>
              <span v-else>
                {{ $t("m.notPrice") }}
              </span>
            </span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          prop="count"
          :label="$t('m.quantity')"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <!-- <el-table-column :label="$t('m.money')" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="$i18n.locale === 'zh_CN'"
              >￥{{ scope.row.mackPriceRmb * scope.row.count }}</span
            >
            <span v-if="$i18n.locale === 'en_US'"
              >${{ scope.row.mackPriceUsd * scope.row.count }}</span
            >
          </template>
        </el-table-column> -->
        <el-table-column :label="$t('m.operate')" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="detail(scope.row)">查看详情</el-button>
            <el-button type="text" @click="del(scope.row)">取消收藏</el-button>
          </template>
        </el-table-column>

        <!--          <el-table-column
              prop="address"
              :label="$t('m.operation')"
              show-overflow-tooltip>
          </el-table-column>-->
      </el-table>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px auto;
        "
      >
        <el-pagination
          background
          layout="prev, pager, next"
          hide-on-single-page
          :page-count="totalPage"
          :current-page="params.page"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <NavFooter></NavFooter>
    </div>
  </div>
</template>

<script>
import NavHeader from "../common/NavHeader.vue";
import NavFooter from "../common/NavFooter.vue";
export default {
  name: "collect",
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      totalPage: 1,
      tableData: [],
      params: {
        page: 1,
        size: 10,
        lag: this.$i18n.locale,
      },
      imageUrl: process.env.VUE_APP_URL+"/v1/commodityManage/previewImage?path=",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查看详情
    detail(row) {
      // console.log(row, "查看详情");
      let routeData = this.$router.resolve({
        path: "/detail",
        query: { id: row.id, priceDisplay:false },
      });
      window.open(routeData.href, "_blank");
    },
    // 取消收藏
    del(row) {
      // console.log(row, "取消收藏");
      this.axios({
        url: "/v1/favor/add",
        method: "get",
        params: {
          id: row.id,
        },
      }).then((res) => {
        // console.log(res, "收藏");
        if (res.data.code == 200) {
          if (res.data.data == "isnotFavor") {
            let message =
              this.$i18n.locale == "zh_CN" ? "取消收藏" : "Cancel Favorite";
            this.$message({
              message: message,
              type: "info",
            });
          } else {
            let message =
              this.$i18n.locale == "zh_CN" ? "收藏成功" : "Success Favorite";
            this.$message({
              message: message,
              type: "success",
            });
          }
          this.params.page == 1;
          this.tableData = [];
          this.getList();
        }
      });
    },
    // 点击单行
    // rowClick(row) {
    //   // console.log(row, "row");
    // },
    // 获取数据
    getList() {
      this.axios({
        url: "/v1/favor/showSelf",
        method: "get",
        params: this.params,
      }).then((res) => {
        // console.log(res);
        if (res.data.code == 200) {
          this.tableData = res.data.data.list.list;
          this.totalPage = Math.ceil(res.data.data.total / 10);
          // console.log(this.totalPage, "this.totalPage");
        }
      });
    },
    // 获取图片
    getImgUrl(img) {
      if (img == null) return;
      img = img.replace(",", "");
      return this.imageUrl + `${img}`;
    },
    // 当前页改变
    changePage(val) {
      // console.log(val, "当前页改变");
      this.params.page = val;
      this.getList();
    },
    changeMeauIndex(id, content) {
      this.meauIndex = id;
      this.content = content;
    },
    switchSeach(codeType, id) {
      console.log("触发了")
      // this.showSelectCard = !this.showSelectCard;
      this.isShowSeach = !this.isShowSeach;
      this.$router.push({
        path: "/searchNew",
        query: {
          codeType: codeType,
          classId: id ? id : null,
          meauIndex: "searchNew",
        },
      });
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  height: 100px;
  position: relative;
  bottom: 0;
  background-color: #303030;
  color: white;
}
</style>
