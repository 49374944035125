<template>
  <div>
    <nav-header :showMenu="showMenu" content="" @changeMeauIndex="changeMeauIndex"
                @switchSeach="switchSeach"></nav-header>
    <div style="width: 1200px; margin: auto">
      <div style="float: left; padding-left: 5px; width: 100%">
        <el-tabs type="border-card" :tab-position="tabPosition" v-model="activeName" style="height: 600px"
          @tab-click="handleClick">
          <el-tab-pane name="0">
            <span slot="label"><i class="el-icon-user"></i>{{ $t("m.personalCenter") }}</span>
            <div style="text-align: left; margin-left: 50px">
              <div style="display: flex;flex-wrap: wrap;">
                <h3 style="width: 100%;">{{ $t("m.userInformation") }}</h3>
                <p style="margin-top: 20px;width: 100%;">
                  {{ $t("m.accountNumber") }}:{{ userInfo.account || '-' }}
                </p>
                <p style="margin-top: 20px;width: 50%;">
                  {{ $t("m.userName") }}:
                  <span v-show="!usereditType">{{ userInfo.nickName || '-' }}</span>
                  <el-input v-show="usereditType" v-model="userInfo.nickName" placeholder="" size="mini"
                    style="width: 200px;"></el-input>
                </p>
                <p style="margin-top: 20px;width: 50%;">
                  {{ $t("m.mobilePhone") }}:
                  <span v-show="!usereditType">{{ userInfo.phone || '-' }}</span>
                  <el-input v-show="usereditType" v-model="userInfo.phone" placeholder="" size="mini"
                    style="width: 200px;"></el-input>
                </p>
                <p style="margin-top: 20px;width: 50%;">
                  QQ:
                  <span v-show="!usereditType">{{ userInfo.qqNum || '-' }}</span>
                  <el-input v-show="usereditType" v-model="userInfo.qqNum" placeholder="" size="mini"
                    style="width: 200px;"></el-input>
                </p>
                <p style="margin-top: 20px;width: 50%;">
                  {{ $t("m.email") }}:
                  <span v-show="!usereditType">{{ userInfo.email || '-' }}</span>
                  <el-input v-show="usereditType" v-model="userInfo.email" placeholder="" size="mini"
                    style="width: 200px;"></el-input>
                </p>
                <p style="margin-top: 20px;width: 50%;">
                  {{ $t("m.instantMessaging") }}:
                  <span v-show="!usereditType">{{ userInfo.wxNum || '-' }}</span>
                  <el-input v-show="usereditType" v-model="userInfo.wxNum" placeholder="" size="mini"
                    style="width: 200px;"></el-input>
                </p>
                <p style="margin-top: 20px;width: 50%;">
                  {{ $t("m.otherContactInformation") }}:
                  <span v-show="!usereditType">{{ userInfo.gogleNum || '-' }}</span>
                  <el-input v-show="usereditType" v-model="userInfo.gogleNum" placeholder="" size="mini"
                    style="width: 200px;"></el-input>
                </p>
                <div v-show="!usereditType" style="width: 100%;margin-top: 20px;">
                  <el-button size="mini" type="primary" @click="useredit">{{ $t("m.edit") }}</el-button>
                </div>
                <div v-show="usereditType" style="width: 100%;margin-top: 20px;">
                  <el-button size="mini" type="primary" @click="updateUser">{{ $t("m.determine") }}</el-button>
                  <el-button size="mini" @click="usercancel">{{ $t("m.cancel") }}</el-button>
                </div>
              </div>
              <hr />
              <h3>{{ $t("m.changePassword") }}</h3>
              <div style="width: 400px; margin-left: -80px">
                <el-form :model="form" ref="form" :rules="rules">
                  <el-form-item :label="$t('m.oldPassword')" prop="oldPassword" label-width="150px">
                    <el-input v-model="form.oldPassword" maxlength="20" show-password size="small"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('m.newPassword')" prop="newPassword" label-width="150px">
                    <el-input v-model="form.newPassword" maxlength="20" show-password size="small"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <el-button type="primary" size="mini" @click="open1('form')">{{ $t("m.determine") }}</el-button>
            </div>
          </el-tab-pane>

          <el-tab-pane name="1">
            <span slot="label"><i class="el-icon-user"></i> {{ $t("m.orderManagement") }}</span>
            <div v-show="!orderDetail">
              <el-table @row-click="rowClick" :data="orderTableData" style="width: 100%" :cell-style="cellStyle"
                :header-cell-style="{
                  background: 'rgb(144, 208, 247)',
                  color: '#fff',
                }">
                <el-table-column prop="orderNum" :label="$t('m.orderNumber')" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="account" :label="$t('m.accountNumber')" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="status" :label="$t('m.state')" width="70" :formatter="statusFormat">
                </el-table-column>
                <el-table-column prop="phone" width="110" :label="$t('m.mobilePhone')">
                </el-table-column>
                <el-table-column prop="price" :label="$t('m.submitTotalPrice')">
                </el-table-column>
                <el-table-column prop="orderPrice" :label="$t('m.finalOffer')">
                </el-table-column>
                <el-table-column prop="submitCurrencyType" :label="$t('m.currencyType')" width="80"
                  :formatter="submitCurrencyType">
                </el-table-column>
                <el-table-column prop="customerName" :label="$t('m.customer')">
                </el-table-column>
                <el-table-column prop="customerPhone" width="110" :label="$t('m.customerPhone')">
                </el-table-column>
                <el-table-column prop="createTime" width="150" :label="$t('m.submissionTime')">
                </el-table-column>
              </el-table>
              <el-pagination background :page-size="orderParams.size" layout="total,prev, pager, next"
                @current-change="handleCurrentChange" :total="orderTotal" style="margin-top: 8px;text-align: right;"
                :hide-on-single-page="true">
              </el-pagination>
            </div>
            <div v-show="orderDetail">
              <div style="display: flex;align-items: center;">
                {{ $t("m.totalAmount") }}：{{ money || '-' }}
                <el-button v-if="orderStatus == 1" type="primary" style="margin-left: 20px;" @click="payment">{{
                  $t("m.payment") }}
                </el-button>
              </div>
              <el-table ref="multipleTable" :data="orderDetailData" tooltip-effect="dark" style="width: 100%;">
                <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                <el-table-column prop="commodityName" :label="$t('m.commodityInformation')" width="300">
                  <template slot-scope="scope">
                    <el-row>
                      <el-col :span="8">
                        <img style="width: 80px; height: 80px" :src="getImgUrl(scope.row.imageUrl)" />
                      </el-col>
                      <el-col :span="12">
                        <span>{{ scope.row.commodityName }}</span>
                      </el-col>
                    </el-row>
                  </template>
                </el-table-column>

                <el-table-column :label="$t('m.unitPrice')" width="120">
                  <template slot-scope="scope">
                    <span v-if="$i18n.locale === 'zh_CN'">
                      ￥{{ scope.row.managePrice }}
                    </span>
                    <span v-if="$i18n.locale === 'en_US'">
                      ${{ scope.row.managePrice }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="count" :label="$t('m.quantity')" show-overflow-tooltip>
                </el-table-column>
                <el-table-column :label="$t('m.confirmPrice')" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <span v-if="$i18n.locale === 'zh_CN'">￥{{ scope.row.confirmPrice }}</span>
                    <span v-if="$i18n.locale === 'en_US'">${{ scope.row.confirmPrice }}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination background :page-size="orderDetailParams.size" layout="total,prev, pager, next"
                @current-change="handleCurrentChange2" :total="orderDetailTotal"
                style="margin-top: 8px;text-align: right;" :hide-on-single-page="true">
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane name="2">
            <span slot="label"><i class="el-icon-place"></i>
              {{ $t("m.addressManagement") }}</span>
            <el-button type="primary" icon="el-icon-plus" style="margin-right: 90%" @click="showAdd">{{
              $t("m.addAddress")
              }}</el-button>
            <div style="width: 98%; margin-top: 10px">
              <el-table :data="addressTableData" style="width: 100%" :header-cell-style="{
                background: 'rgb(144, 208, 247)',
                color: '#fff',
              }">
                <el-table-column prop="name" :label="$t('m.fullName')" width="100">
                </el-table-column>
                <el-table-column prop="phone" :label="$t('m.mobilePhone')" width="150">
                </el-table-column>
                <el-table-column prop="address" :label="$t('m.address')" width="300" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="createTime" :label="$t('m.date')" width="180" :formatter="dateFormat">
                </el-table-column>
                <el-table-column :label="$t('m.operation')">
                  <template slot-scope="scope">
                    <el-row>
                      <el-tooltip class="item" effect="dark" :content="$t('m.edit')" placement="top-end">
                        <el-button type="primary" icon="el-icon-edit" circle size="mini"
                          @click="edit(scope.$index, scope.row)"
                          style="margin-right: 5px; margin-left: 0px"></el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" :content="$t('m.delete')" placement="top-end">
                        <el-popconfirm :title="$t('m.areYouDelete')" @confirm="deleteUser(scope.$index, scope.row)">
                          <el-button slot="reference" type="danger" icon="el-icon-delete" circle
                            size="mini"></el-button>
                        </el-popconfirm>
                      </el-tooltip>
                    </el-row>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination background :page-size="siteParams.size" layout="total,prev, pager, next"
                @current-change="handleCurrentChange3" :total="siteTotal" style="margin-top: 8px;text-align: right;"
                :hide-on-single-page="true">
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane name="3">
            <span slot="label" @click="open"><i class="el-icon-switch-button"></i>
              {{ $t("m.safeExit") }}</span>
          </el-tab-pane>
        </el-tabs>
        <el-dialog :title="$t('m.receivingAddress')" :visible.sync="dialogFormVisible" width="500px;">
          <el-form :model="form">
            <el-form-item :label="$t('m.fullName')" :label-width="formLabelWidth">
              <el-input v-model="form.name" autocomplete="off" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.mobilePhone')" :label-width="formLabelWidth">
              <el-input v-model="form.phone" autocomplete="off" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.address')" :label-width="formLabelWidth">
              <el-input v-model="form.address" autocomplete="off" style="width: 300px"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">{{
              $t("m.cancel")
            }}</el-button>
            <el-button type="primary" @click="addUser">{{
              $t("m.determine")
            }}</el-button>
          </div>
        </el-dialog>
        <!-- 支付 -->
        <el-dialog :title="$t('m.payment')" :visible.sync="paymentVisible" width="500px;">
          <div class="paydata">
            <div class="payImg">
              <div v-for="item in paymentData.Img" :key="item.id">
                <img :src="getImgUrl(item.valueStr)">
                <div>{{ item.name }}</div>
              </div>
            </div>
            <div class="payAccount">
              <div v-for="item in paymentData.Account" :key="item.id">
                <div>{{ item.name }}：{{ item.valueStr }}</div>
              </div>
            </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="paymentVisible = false">{{
              $t("m.cancel")
            }}</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div style="float: left; width: 100%">
      <div style="height: 70px"></div>
      <nav-footer style="
          height: 100px;
          position: relative;
          bottom: 0;
          background-color: #303030;
          color: white;
        "></nav-footer>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import NavHeader from "../common/NavHeader.vue";
import NavFooter from "../common/NavFooter.vue";
export default {
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      userInfo: {},
      activeName: this.$route.query.name,
      showMenu: false,
      tabPosition: "left",
      addressTableData: [],
      orderTableData: [],
      dialogTableVisible: false,
      dialogFormVisible: false,

      orderDetail: false,
      orderDetailData: [],
      // 订单商品
      ordderdetailid: "",
      orderDetailTotal: 0,
      orderDetailParams: {
        page: 1,
        size: 4,
        lag: this.$i18n.locale,
      },
      // 订单分页
      orderTotal: 0,
      orderParams: {
        page: 1,
        size: 8,
      },
      // 地址分页
      siteTotal: 0,
      siteParams: {
        page: 1,
        size: 8,
      },
      formpwd: {
        oldPassword: "",
        newPassword: "",
      },
      form: {
        name: "",
        phone: "",
        address: "",
        id: "",
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
      formLabelWidth: "100px",
      orderStatus: false,
      money: '',
      paymentVisible: false,
      paymentData: {
        Account: [],
        Img: [],
      },
      usereditType: false,
    };
  },
  created() {
    this.queryOrderList();
    this.getUserInfo();
  },
  methods: {
    useredit() {
      this.usereditType = true;
    },
    usercancel() {
      this.usereditType = false;
      this.getUserInfo();
    },
    updateUser() {
      if (this.userInfo.phone == '') {
        this.$message({
          type: "error",
          message: this.$i18n.locale == "zh_CN" ? "请输入手机号" : "Please enter your phone number",
        });
        return false;
      }
      this.axios({
        method: "post",
        url: "/v1/webUser/updateUser",
        data: {
          nickName: this.userInfo.nickName,
          phone: this.userInfo.phone,
          qqNum: this.userInfo.qqNum,
          email: this.userInfo.email,
          wxNum: this.userInfo.wxNum,
          gogleNum: this.userInfo.gogleNum
        },
      }).then((res) => {
        // console.log('用户修改',res);
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: this.$i18n.locale == "zh_CN" ? "修改成功" : "Modified successfully",
          });
          this.usereditType = false;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    getpaymentData() {
      this.axios({
        method: "get",
        url: "/v1/config/listpay",
        params: {
          lag: this.$i18n.locale
        },
      }).then((res) => {
        // console.log('支付',res);
        if (res.data.code === 200) {
          this.paymentData = res.data.data;
        }
      });
    },
    getOrderDetail() {
      this.axios({
        method: "get",
        url: "/v1/front/order/queryCommodityList/" + this.ordderdetailid,
        params: this.orderDetailParams,
      }).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.orderDetailData = res.data.data.list;
          this.orderDetailTotal = res.data.data.total;
        }
      });
    },
    getImgUrl(img) {
      if (img !== null && img !== undefined) {
        let imgs = img.split(",");
        if (imgs.length > 0) {
          return (
            process.env.VUE_APP_URL + "/v1/commodityManage/previewImage?path=" + img.split(",")[0]
          );
        }
      }
      return "";
    },
    getUserInfo() {
      this.axios({
        method: "get",
        url: "/v1/front/user/selectUserDetail",
        params: {},
      }).then((res) => {
        // console.log('用户',res);
        if (res.data.code === 200) {
          this.userInfo = res.data.data;
        }
      });
    },
    open1(formpwd) {
      this.$refs[formpwd].validate((valid) => {
        if (valid) {
          this.$confirm(
            this.$t("m.areYouSureToChangeThePassword"),
            this.$t("m.tips"),
            {
              confirmButtonText: this.$t("m.determine"),
              cancelButtonText: this.$t("m.cancel"),
              type: "warning",
            }
          ).then((res) => {
            console.log(res);
            this.axios({
              method: "post",
              url: "/v1/webUser/updatePass",
              data: this.form,
            }).then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: this.$i18n.locale == "zh_CN" ? "修改成功" : "Modified successfully",
                });
              } else {
                this.$message({
                  type: "error",
                  message: res.data.message,
                });
              }
            });
          });
        } else {
          return false;
        }
      });
    },
    open() {
      this.$confirm(this.$t("m.areYouSureToExit"), this.$t("m.tips"), {
        confirmButtonText: this.$t("m.determine"),
        cancelButtonText: this.$t("m.cancel"),
        type: "warning",
      }).then(() => {
        this.axios({
          method: "post",
          url: "/v1/webUser/userOut",
        }).then((res) => {
          if (res.data.code === 200) {
            localStorage.removeItem("userName");
            localStorage.removeItem("token");
            this.$router.push("/");
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      });
    },
    handleClick(tab) {
      if (tab.name === "1") {
        this.orderDetail = false;
        this.queryOrderList();
      }
      if (tab.name === "2") {
        this.queryAddressList();
      }
    },
    submitCurrencyType(row) {
      if (row.submitCurrencyType === 1) {
        return this.$t("m.dollar");
      } else if (row.submitCurrencyType === 0) {
        return this.$t("m.RMB");
      }
    },
    statusFormat(row) {
      if (row.status === 0) {
        return this.$t("m.submitted");
      } else if (row.status === 1) {
        return this.$t("m.confirmed");
      } else {
        return this.$t("m.completed");
      }
    },
    queryAddressList() {
      this.axios({
        method: "get",
        url: "/v1/front/address/queryAddressList",
        params: this.siteParams,
      }).then((res) => {
        // console.log('地址',res)
        if (res.data.code === 200) {
          this.addressTableData = res.data.data.list;
          this.siteTotal = res.data.data.total;
        }
      });
    },
    queryOrderList() {
      this.axios({
        method: "get",
        url: "/v1/front/order/queryOrderList",
        params: this.orderParams,
      }).then((res) => {
        // console.log(res)
        if (res.data.code === 200) {
          this.orderTableData = res.data.data.list;
          this.orderTotal = res.data.data.total;
        }
      });
    },
    dateFormat: function (row) {
      return moment(row.createTime).format("YYYY-MM-DD HH:mm:ss");
    },
    edit(index, row) {
      this.dialogFormVisible = true;
      this.form.name = row.name;
      this.form.phone = row.phone;
      this.form.address = row.address;
      this.form.id = row.id;
    },
    // 支付弹窗
    payment() {
      this.getpaymentData();
      this.paymentVisible = true;
    },
    showAdd() {
      this.dialogFormVisible = true;
      this.form.name = "";
      this.form.phone = "";
      this.form.address = "";
      this.form.id = "";
    },
    deleteUser(index, row) {
      this.axios({
        method: "delete",
        url: "/v1/front/address/deleteAddress",
        params: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.queryAddressList();
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    addUser() {
      this.dialogFormVisible = false;
      this.axios({
        method: "post",
        url: "/v1/front/address/addAddress",
        data: this.form,
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            message: this.$i18n.locale == "zh_CN" ? "上传成功" : "Upload successfully",
            type: "success",
          });
          this.queryAddressList();
        }
      });
    },
    handleCurrentChange(val) {
      this.orderParams.page = val;
      this.queryOrderList();
    },
    handleCurrentChange2(val) {
      this.orderDetailParams.page = val;
      this.getOrderDetail();
    },
    handleCurrentChange3(val) {
      this.siteParams.page = val;
      this.queryAddressList();
    },
    rowClick(row) {
      this.orderDetail = true;
      // console.log(row);
      this.orderStatus = row.status;
      this.money = row.orderPrice;
      this.ordderdetailid = row.id;
      this.getOrderDetail();
    },
    cellStyle() {
      return "cursor:pointer;";
    },
    changeMeauIndex(id, content) {
      this.meauIndex = id;
      this.content = content;
    },
    switchSeach(codeType, id) {
      console.log("触发了")
      // this.showSelectCard = !this.showSelectCard;
      this.isShowSeach = !this.isShowSeach;
      this.$router.push({
        path: "/searchNew",
        query: {
          codeType: codeType,
          classId: id ? id : null,
          meauIndex: "searchNew",
        },
      });
    },
  },
};
</script>
<style>
.el-table .el-table__cell {
  padding: 6px 0;
}

.payImg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}

.payImg>div {
  margin: 0 10px 20px;
}

.payImg>div>img {
  display: block;
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.payImg>div>div {
  text-align: center;
}

.payAccount>div {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}
</style>
