import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import VueRouter from "vue-router";
import routers from "./router/index";
import axios from "axios";
import VueAxios from "vue-axios";
import "normalize.css/normalize.css";
axios.defaults.baseURL = process.env.VUE_APP_URL;


import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.use(SlideVerify);

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import VueI18n from "vue-i18n";
import ElementEnLocale from "element-ui/lib/locale/lang/en";
import ElementCnLocale from "element-ui/lib/locale/lang/zh-CN";
import Locale from "element-ui/lib/locale";

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale:
  localStorage.getItem("lag") === null
      ? "zh_CN"
      : localStorage.getItem("lag"), // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    zh_CN: {
      ...require("./lang/zh"),
      ...ElementCnLocale,
    }, // 中文语言包
    en_US: {
      ...require("./lang/en"),
      ...ElementEnLocale,
    }, // 英文语言包
  },
});

Locale.i18n((key, value) => i18n.t(key, value));

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    token && (config.headers.token = token);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

axios.interceptors.response.use((response) => {
  // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
  // 否则的话抛出错误
  if (response.data.code === 203) {
    localStorage.removeItem("token");
    router
      .push({
        path: "/login",
      })
      .catch((error) => {
        return error;
      });
    return Promise.resolve(response);
  } else {
    return Promise.resolve(response);
  }
});

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
const router = new VueRouter({
  mode: "hash",
  routes: routers,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
new Vue({
  render: (h) => h(App),
  router,
  i18n,
}).$mount("#app");
