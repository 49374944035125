<template>
  <div>
    <nav-header @getChildValue="getChildValue" :showMenu="showMenu" :content="params.content"></nav-header>
    <div style="width: 1200px; margin: auto">
      <div style="margin-top: 20px">
        <div class="tb_div"></div>
        <div class="jg_div">
          <el-form :inline="true" class="demo-form-inline" label-width="80px">
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('m.page1')" style="display: flex" class="item_line">
                  <el-input :placeholder="$t('m.span1')" v-model="query.name" style="width: 100%" clearable
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('m.page2')" style="display: flex" class="item_line">
                  <el-input :placeholder="$t('m.span2')" v-model="query.codeType" style="width: 100%" clearable
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('m.page3')" style="display: flex" class="item_line">
                  <el-input :placeholder="$t('m.span3')" v-model="query.packing" style="width: 100%" clearable
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('m.page4')" style="display: flex" class="item_line">
                  <el-input :placeholder="$t('m.span4')" v-model="query.priceStart" style="width: 45%" clearable
                    size="mini"></el-input>
                  <span style="margin-left: 5%;margin-right: 5%">-</span>
                  <el-input :placeholder="$t('m.span5')" v-model="query.priceEnd" style="width: 45%" clearable
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('m.page5')" style="display: flex" class="item_line">
                  <el-input :placeholder="$t('m.span6')" v-model="query.ctn1" style="width: 45%" clearable
                    size="mini"></el-input>
                  <span style="margin-left: 5%;margin-right: 5%">-</span>
                  <el-input :placeholder="$t('m.span7')" v-model="query.ctn2" style="width: 45%" clearable
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item :label="$t('m.page6')" style="display: flex" class="item_line">
                  <el-select v-model="query.hasimageUrl" clearable size="mini" :placeholder="$t('m.span8')"
                    style="width: 100%">
                    <el-option label="否" :value="0"></el-option>
                    <el-option label="是" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('m.page7')" style="display: flex" class="item_line">
                  <el-input :placeholder="$t('m.span9')" v-model="query.Lmin" style="width: 45%;" clearable
                    size="mini"></el-input>
                  <span style="margin-left: 5%;margin-right: 5%">-</span>
                  <el-input :placeholder="$t('m.span9')" v-model="query.Lmax" style="width: 45%;" clearable
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('m.page8')" style="display: flex" class="item_line">
                  <el-input :placeholder="$t('m.span10')" v-model="query.Wmin" style="width: 45%;" clearable
                    size="mini"></el-input>
                  <span style="margin-left: 5%;margin-right: 5%">-</span>
                  <el-input :placeholder="$t('m.span10')" v-model="query.Wmax" style="width: 45%;" clearable
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item :label="$t('m.page9')" style="display: flex" class="item_line">
                  <el-input :placeholder="$t('m.span11')" v-model="query.Hmin" style="width: 45%;" clearable
                    size="mini"></el-input>
                  <span style="margin-left: 5%;margin-right: 5%">-</span>
                  <el-input :placeholder="$t('m.span11')" v-model="query.Hmax" style="width: 45%;" clearable
                    size="mini"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('m.page10')" style="display: flex" class="item_line">
                  <el-date-picker v-model="time" type="daterange" unlink-panels range-separator="至"
                    :start-placeholder="$t('m.page12')" :end-placeholder="$t('m.page13')" value-format="timestamp"
                    :picker-options="pickerOptions" style="width: 100%" size="mini">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12" style="display: flex;justify-content: flex-end">
                <el-form-item>
                  <el-button type="primary" @click="checkData" size="mini">查询</el-button>
                  <el-button type="info" @click="reset" size="mini">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div>
        <div class="px_div">
          <el-button @click="sortChange1">{{ $t("m.newest") }}
            <i class="el-icon-bottom" v-show="this.newestrow === 'desc'"></i>
            <i class="el-icon-top" v-show="this.newestrow === 'asc'"></i>
          </el-button>
          <el-button @click="sortChange2">{{ $t("m.price") }}
            <i class="el-icon-bottom" v-show="this.pricerow === 'desc'"></i>
            <i class="el-icon-top" v-show="this.pricerow === 'asc'"></i>
          </el-button>
        </div>
      </div>
      <div class="ul_div">
        <ul style="overflow: hidden;">
          <li @click.stop="toDetail(commodity.id)" v-for="(commodity, key) in commodityList" :key="key"
            class="hover_style">
            <div class="nr_div">
              <img style="height: 180px; width: 100%;object-fit: contain;" :src="getImgUrl(commodity.imageUrl)" />
              <div class="commodity_Code" style="overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;    margin: 8px 14px;">
                {{ commodity.name }}
              </div>
              <div style="text-align: left">
                <h3>{{ commodity.code }}</h3>
                <!--                  <p style="color: red">{{$t('m.notPrice')}}</p>-->
                <!--                  <p style="color: red" v-else-if="$i18n.locale === 'zh_CN'">￥{{commodity.mackPriceRmb}}</p>
                  <p style="color: red" v-else-if="$i18n.locale === 'en_US'">${{commodity.mackPriceUsd}}</p>-->
              </div>
              <!-- <div class="commodity_Name" v-show="$i18n.locale == 'zh_CN'">
                &yen;{{ commodity.mackPriceRmb }}
              </div>
              <div class="commodity_Name" v-show="$i18n.locale == 'en_US'">
                {{ commodity.mackPriceUsd }}$
              </div> -->
              <div style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  margin: 8px 14px;
                ">
                <img style="width: 16px; height: 14px" src="../../assets/img/collect_active.png" alt=""
                  v-if="commodity.favor" @click.stop="changeLike(commodity)" />
                <img style="width: 16px; height: 14px" src="../../assets/img/collect.png" alt=""
                  @click.stop="changeLike(commodity)" v-else />
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16"
                  height="16" style="margin-left: 8px;" @click.stop="changeLikeShop(commodity)" viewBox="0 0 24 24">
                  <path
                    d="M11 9h2V6h3V4h-3V1h-2v3H8v2h3v3zm-4 9c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2s-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2s2-.9 2-2s-.9-2-2-2zm-8.9-5h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01L19.42 4l-3.87 7H8.53L4.27 2H1v2h2l3.6 7.59l-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2z"
                    fill="currentColor"></path>
                </svg>
              </div>
            </div>
          </li>
        </ul>
        <div v-show="commodityList.length <= 0" style="text-align: center;margin: 20px 0;">暂无商品</div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;margin: 20px 0;">
      <el-pagination background layout="prev, pager, next" :page-count="totalPage" :current-page="params.page"
        @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import NavHeader from "../common/NavHeader.vue";
import NavFooter from "../common/NavFooter.vue";
let { throttle } = require("debounce-throttle-args");
export default {
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      throttle: throttle,
      priceRadio: "",
      volume: "",
      ctnRadio: "",
      pricerow: "desc",
      newestrow: "desc",
      showMenu: false,
      commodityList: [],
      checkboxGroup: [],
      menus: [],
      params: {
        lag: this.$i18n.locale,
        page: 1,
        size: 180,
        content: '',
        name: '',
        codeType: "",
        id: '',
        orderName: "",
        orderRule: "desc",
        priceStart: "",
        priceEnd: "",
        packing: "",
        menuId: 0,
        volume1: "",
        volume2: "",
        ctn1: "",
        ctn2: "",
      },
      time: [],
      query: {
        lag: this.$i18n.locale,
        id: '',
        page: 1,
        size: 100,
        content: '',
        name: "",
        hasimageUrl: "",
        Lmin: "",
        Lmax: "",
        Wmin: "",
        Wmax: "",
        Hmin: "",
        Hmax: "",
        packing: "",
        dateMin: "",
        dateMax: "",
        codeType: "",
        packing: "",
        priceStart: "",
        priceEnd: "",
        ctn1: "",
        ctn2: "",
        menuId: 0,
      },
      radioOne: "",
      radioTwo: "",
      radioThree: "",
      radioOneText: "",
      radioTwoText: "",
      radioOnetype: false,
      radioTwotype: false,
      menusOne: [],
      menusTwo: [],
      menusThree: [],
      imageUrl: process.env.VUE_APP_URL + "/v1/commodityManage/previewImage?path=",
      lag: "",
      totalPage: 1,
    };
  },
  watch: {
    '$route.query': {
      handler(newVal) {
        // console.log('变化',newVal);
        if (newVal.search || newVal.search == '') {
          this.params.content = newVal.search;
          this.search();
        }
        if (newVal.id) {
          this.params.id = newVal.id;
          this.judgeClass();
          this.search();
        }
        if (newVal.menuId) {
          this.params.menuId = newVal.menuId;
          this.search();
        }
      },
      deep: true
    },
  },
  created() {
    if (this.$route.query.id) {
      this.params.id = this.$route.query.id;
    }
    if (this.$route.query.menuId) {
      this.params.menuId = this.$route.query.menuId;
    }
    if (this.$route.query.search) {
      this.params.content = this.$route.query.search;
    }
    if (this.$route.query.new) {
      this.params.orderName = "createTime";
      this.params.orderRule = "desc";
      this.newestrow = "desc";
    }
    this.getshowAll();
    this.selectCommodityList();
  },
  methods: {

    //  重置
    reset() {
      this.query = {
        lag: this.$i18n.locale,
        id: '',
        page: 1,
        size: 100,
        content: '',
        name: "",
        hasimageUrl: "",
        Lmin: "",
        Lmax: "",
        Wmin: "",
        Wmax: "",
        Hmin: "",
        Hmax: "",
        packing: "",
        dateMin: "",
        dateMax: "",
        codeType: "",
        packing: "",
        priceStart: "",
        priceEnd: "",
        ctn1: "",
        ctn2: "",
        menuId: 0,
      };
      this.time = [];
      this.checkData();
    },
    //  搜索
    checkData() {
      if (this.time == null) {
        this.time = []
        this.query.dateMin = '';
        this.query.dateMax = '';
      }
      this.query.page = 1;
      this.query.size = 100;
      if (this.time.length > 0) {
        this.query.dateMin = this.time[0];
        this.query.dateMax = this.time[1];
      }
      this.selectCommodityList();
    },
    // 加入购物车
    changeLikeShop(item) {
      console.log(item);
      this.axios({
        method: "post",
        url: "/v1/shoppingCart/addToCart",
        params: {
          commodityId: item.id,
          count: 1,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: "success",
            message: "加入购物车成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    // 判断分类
    judgeClass() {
      for (let i = 0; i < this.menusOne.length; i++) {
        for (let y = 0; y < this.menusOne[i].list.length; y++) {
          for (let z = 0; z < this.menusOne[i].list[y].list.length; z++) {
            if (this.menusOne[i].list[y].list[z].id == this.params.id) {
              this.radioOne = this.menusOne[i].id;
              this.menusTwo = this.menusOne[i].list;
              this.radioTwo = this.menusOne[i].list[y].id;
              this.menusThree = this.menusOne[i].list[y].list;
              this.radioThree = this.menusOne[i].list[y].list[z].id;
              this.radioOnetype = false;
              this.radioTwotype = false;
              this.radioOneText = this.menusOne[i].name;
              this.radioTwoText = this.menusOne[i].list[y].name;
            }
          }
        }

      }
    },
    // 获取全分类
    getshowAll() {
      this.axios({
        url: "/v1/commodityType/showAll",
        method: "get",
        params: {
          lag: this.$i18n.locale,
        },
      }).then((res) => {
        // console.log(res, "获取全分类");
        let list = res.data.data.list;
        // console.log(list);
        this.menusOne = list;
        if (this.params.id) {
          this.judgeClass();
        }
      });
    },
    search() {
      this.params.page = 1;
      this.commodityList = [];
      this.selectCommodityList();
    },
    resetting() {
      this.commodityList = [];
      this.priceRadio = "";
      this.volume = "";
      this.ctnRadio = "";
      this.params = {
        lag: this.$i18n.locale,
        page: 1,
        size: 180,
        content: '',
        name: '',
        codeType: "",
        id: '',
        orderName: "",
        orderRule: "desc",
        priceStart: "",
        priceEnd: "",
        packing: "",
        menuId: 0,
        volume1: "",
        volume2: "",
        ctn1: "",
        ctn2: "",
      };
      this.radioOne = "";
      this.radioTwo = "";
      this.radioThree = "";
      this.menusTwo = [];
      this.menusThree = [];
      this.radioOneText = "";
      this.radioTwoText = "";
      this.radioOnetype = false;
      this.radioTwotype = false;
      this.selectCommodityList();
    },
    // 是否收藏
    changeLike(item) {
      let token = localStorage.getItem("token");
      if (!token) {
        return this.$message({
          message: this.$i18n.locale == "zh_CN" ? "请先登录" : "Please log in first",
          type: "info",
        });
      }
      this.axios({
        url: "/v1/favor/add",
        method: "get",
        params: {
          id: item.id,
        },
      }).then((res) => {
        // console.log(res, "收藏");
        if (res.data.code == 200) {
          if (res.data.data == "isnotFavor") {
            item.favor = false;
            let message =
              this.$i18n.locale == "zh_CN" ? "取消收藏" : "Cancel Favorite";
            this.$message({
              message: message,
              type: "info",
            });
          } else {
            item.favor = true;
            let message =
              this.$i18n.locale == "zh_CN" ? "收藏成功" : "Success Favorite";
            this.$message({
              message: message,
              type: "success",
            });
          }
          // this.params.page == 1;
          // this.commodityList = [];
          // this.selectCommodityList();
        }
      });
    },
    filterName(name) {
      if (name == null) return;
      let str = JSON.parse(name.name);
      return this.$i18n.locale == "zh_CN" ? str.zh_CN : str.en_US;
    },
    changePrice() {
      if (this.priceRadio !== "all") {
        const prices = this.priceRadio.split("-");
        this.params.priceStart = prices[0];
        this.params.priceEnd = prices[1];
      } else {
        this.params.priceStart = "";
        this.params.priceEnd = "";
      }
      //   this.selectCommodityList();
    },
    changeVolume() {
      if (this.volume !== "all") {
        const prices = this.volume.split("-");
        // console.log(prices, "prices");
        this.params.volume1 = prices[0];
        this.params.volume2 = prices[1];
      } else {
        this.params.volume1 = "";
        this.params.volume2 = "";
      }
      //   this.selectCommodityList();
    },
    changeCtn() {
      if (this.ctnRadio !== "all") {
        const prices = this.ctnRadio.split("-");
        this.params.ctn1 = prices[0];
        this.params.ctn2 = prices[1];
      } else {
        this.params.ctn1 = "";
        this.params.ctn2 = "";
      }
      //   this.selectCommodityList();
    },
    // 最新优先
    sortChange1() {
      this.params.orderName = "createTime";
      if (this.params.orderRule === "desc") {
        this.params.orderRule = "asc";
        this.newestrow = "asc";
      } else {
        this.params.orderRule = "desc";
        this.newestrow = "desc";
      }
      this.params.page = 1;
      this.commodityList = [];
      this.selectCommodityList();
    },
    // 价格优先
    sortChange2() {
      if (this.$i18n.locale === "zh_CN") {
        this.params.orderName = "mackPriceRmb";
      } else if (this.$i18n.locale === "en_US") {
        this.params.orderName = "mackPriceUsd";
      }
      if (this.params.orderRule === "desc") {
        this.params.orderRule = "asc";
        this.pricerow = "asc";
      } else {
        this.params.orderRule = "desc";
        this.pricerow = "desc";
      }
      this.params.page = 1;
      this.commodityList = [];
      this.selectCommodityList();
    },
    getMore() {
      if (this.params.page > this.totalPage) return;
      this.params.page += 1;
      this.selectCommodityList();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.selectCommodityList();
    },
    setDefaultData() {
      this.selectCommodityList();
    },
    radioOneclick() {
      this.radioOnetype = !this.radioOnetype;
      this.radioTwotype = false;
    },
    radioTwoclick() {
      this.radioTwotype = !this.radioTwotype;
      this.radioOnetype = false;
    },
    // 改变一级分类
    changeRadioOne(val) {
      // console.log(val);
      this.params.id = val;
      this.radioTwo = "";
      this.radioThree = "";
      this.menusThree = [];
      this.radioTwoText = "";
      for (let i = 0; i < this.menusOne.length; i++) {
        if (this.menusOne[i].id == val) {
          this.radioOneText = this.menusOne[i].name;
          this.menusTwo = this.menusOne[i].list;
        }
      }
      this.radioOnetype = false;
    },
    // 改变二级分类
    changeRadioTwo(val) {
      // console.log(val);
      this.params.id = val;
      this.radioThree = "";
      for (let i = 0; i < this.menusTwo.length; i++) {
        if (this.menusTwo[i].id == val) {
          this.radioTwoText = this.menusTwo[i].name;
          this.menusThree = this.menusTwo[i].list;
        }
      }
      this.radioTwotype = false;
    },
    // 改变三级分类
    changeRadioThree(val) {
      this.params.id = val;
    },
    // 获取顶级分类
    async getTopClass() {
      let childOne = await this.axios({
        method: "get",
        url: "/v1/commodityType/showTop",
        params: {
          lag: this.$i18n.locale,
          type: 1,
        },
      });
      this.menusOne = childOne.data.data.list;
      if (!this.$route.query.classId) {
        this.radioOne = this.menusOne[0].id;
      }

      this.getTwoClass();
    },
    // 获取二级分类
    getTwoClass() {
      this.axios({
        method: "get",
        url: "/v1/commodityType/showTopSubsid",
        params: {
          id1: this.radioOne,
        },
      }).then((res) => {
        // console.log(res.data.data.list, "二级分类");
        this.menusTwo = res.data.data.list;
        // console.log(this.menusTwo, "this.menusTwo");
        this.radioTwo = this.menusTwo[0].id;
        this.getThreeClass();
      });
    },
    // 获取三级分类
    getThreeClass() {
      this.axios({
        method: "get",
        url: "/v1/commodityType/showTopSubcode",
        params: {
          id1: this.radioTwo,
        },
      }).then((res) => {
        // console.log(res.data.data.list, "三级分类");
        this.menusThree = res.data.data.list;
        this.radioThree = this.menusThree[0].id;
        this.selectCommodityList();
        // console.log(this.menusThree, "this.menusThree");
      });
    },
    // 获取图片
    getImgUrl(img) {
      if (img == null) return;
      img = img.replace(",", "");
      return this.imageUrl + `${img}`;
    },
    toDetail(id) {
      let routeData = this.$router.resolve({
        path: "/detail",
        query: { id: id, priceDisplay: false },
      });
      window.open(routeData.href, "_blank");
    },
    // 商品列表
    selectCommodityList() {
      console.log(this.params, "this.params");
      this.axios({
        method: "get",
        url: "/v1/front/manage/queryCommodityManageList",
        params: this.query,
      }).then((res) => {
        // this.axios({
        //   method: "get",
        //   url: "/v1/commodityManage/all",
        //   params: this.query,
        // }).then((res) => {
        // console.log(res.data.data, "data");
        // console.log(res.data.data.list, "列表");
        this.commodityList = res.data.data.list;
        this.totalPage = Math.ceil(res.data.data.total / 180);
        document.documentElement.scrollTop = 0;
        // for (let i = 0; i < res.data.data.list.length; i++) {
        //   this.commodityList.push(res.data.data.list[i]);
        // }
      });
    },
    getChildValue() {
      location.reload();
    },
  },
};
</script>

<style scoped>
.commodity_Name {
  font-size: 14px;
  font-weight: 400;
  margin: 8px 14px;
}

.screen_div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
}

.screen_name_div {
  font-size: 14px;
  margin-right: 10px;
  min-width: 60px;
  text-align: right;
}

.grade_div {
  font-size: 14px;
  margin-right: 10px;
  min-width: 60px;
  text-align: right;
}

.grade_right {
  width: calc(100% - 80px);
}

.grade_right .el-radio.is-bordered+.el-radio.is-bordered {
  margin-left: 0;
  margin-bottom: 5px;
  margin-top: 5px;
}

.tb_div {
  background-color: #fafafa;
  height: 38px;
  border: 1px solid #eee;
}

.bq_div {
  /* min-height: 100px; */
  border: 1px solid #eee;
  border-top: 0px;
}

.jg_div {
  height: auto;
  border: 1px solid #eee;
  border-top: 0px;
}

.pr_div {
  height: 100%;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fl_div {
  background-color: #fafafa;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ck_div {
  /* height: 66px; */
  border: none;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;
}

.ck_div_button {
  border: 1px solid #DCDFE6;
  color: #606266;
  border-radius: 3px;
  height: 28px;
  line-height: 28px;
  margin-right: 30px;
  margin-bottom: 5px;
  margin-top: 5px;
  cursor: pointer;
  min-width: 120px;
  text-align: center;
}

.ul_div {
  margin-top: 20px;
  /* float: left; */
  padding-left: 5px;
  width: 100%;
}

.nr_div {
  float: left;
  height: 300px;
  /* height: 242px; */
  width: 100%;
  border: #eee solid 1px;
  box-sizing: border-box;
}

.px_div {
  margin-top: 20px;
  text-align: left;
}

.hover_style {
  float: left;
  margin: 10px 4px;
  width: 16%;
  border: #ffffff solid 3px;
  height: 300px;
  box-sizing: border-box;
}

.hover_style:hover {
  cursor: pointer;
  border: rgb(35, 172, 255) solid 3px;
}

.type_hover_style:hover {
  cursor: pointer;
  color: rgb(35, 172, 255);
  border: rgb(35, 172, 255) solid 1px;
}

.el-checkbox {
  margin-right: 0;
}

h3,
h4 {
  font-weight: normal;
  margin: 8px 14px;
}

h4:hover {
  cursor: pointer;
}

.footer_class {
  width: 100%;
  height: 100px;
  position: relative;
  bottom: 0;
  background-color: #303030;
  color: white;
}

.commodity_Code:hover {
  white-space: normal !important;
}

.item_line {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 10px;
}
</style>
