import Home from "../components/views/Home";
import Login from "../components/views/Login";
import Register from "../components/views/Register";
import Search from "../components/views/Search";
import searchNew from "../components/views/searchNew";
import ShopCart from "../components/views/ShopCart";
import Detail from "../components/views/Detail";
import Personal from "../components/views/Personal";
import InfoMessage from "../components/views/InfoMessage";
import Classification1 from "../components/views/Classification1";
import Classification2 from "../components/views/Classification2";
import Pay from "../components/views/Pay";
import Collect from "../components/views/collect";
const routers = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/searchNew",
    name: "searchNew",
    component: searchNew,
  },
  {
    path: "/shopCart",
    name: "ShopCart",
    component: ShopCart,
  },
  {
    path: "/detail",
    name: "Detail",
    component: Detail,
  },
  {
    path: "/personal",
    name: "Personal",
    component: Personal,
  },
  {
    path: "/infoMessage",
    name: "InfoMessage",
    component: InfoMessage,
  },
  {
    path: "/classification1",
    name: "Classification1",
    component: Classification1,
  },
  {
    path: "/classification2",
    name: "Classification2",
    component: Classification2,
  },
  {
    path: "/pay",
    name: "Pay",
    component: Pay,
  },
  {
    path: "/collect",
    name: "Collect",
    component: Collect,
  },
];

export default routers;
