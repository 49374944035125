<template>
  <div style="background-color: white; width: 100%">
    <div class="header">
      <div class="n-header">
        <el-container>
          <el-aside style="width: 600px;">
            <div>
              <div v-if="uname === undefined || uname === null">
                <span style="cursor: default">{{ $t("m.helloWelcome") }}</span>
                <span @click="login">[{{ $t("m.signIn") }}]</span>
                <span @click="register">[{{ $t("m.register") }}]</span>
              </div>
              <div v-else>
                <span>{{ $t("m.helloWelcome") }}</span>
                <span>{{ uname }}</span>
                <span @click="out" style="color: #d80909">[{{ $t("m.safeExit") }}]</span>
              </div>
            </div>
          </el-aside>
          <el-main>
            <div style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
              <span>
                <a @click="personal"><img src="../../assets/img/personal.png" alt="" />{{
                  $t("m.personalCenter")
                }}</a>
              </span>
              <span>
                <a @click="toCollect"><img src="../../assets/img/collect.png" alt="" />{{
                  $t("m.collect")
                }}</a>
              </span>
              <span>
                <a @click="toCart"><img src="../../assets/img/cart.png" alt="" />{{
                  $t("m.shoppingCart")
                }}</a>
              </span>
              <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                  <img src="../../assets/img/language.png" alt="" />{{
                    $t("m.language")
                  }}
                  <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="zh_CN">{{
                    $t("m.chinese")
                  }}</el-dropdown-item>
                  <el-dropdown-item command="en_US">{{
                    $t("m.english")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-main>
        </el-container>
      </div>
      <div class="s_header">
        <!--          <el-col :xs="24" :sm="10" :md="12" :lg="12">
            <img style="width: 245px;height: 60px" :src="getImgUrl(this.logoImage)" alt=""/>
          </el-col>
          <el-col :xs="24" :sm="14" :md="12" :lg="11" style="margin-top: 12px">
            <el-input
                v-model="search"
                :placeholder="$t('m.pleaseContent')"
                style="width: 360px;color:#ee0a24"
            />
            <el-button type="primary" icon="el-icon-search" @click="searchList">{{$t('m.search')}}</el-button>
          </el-col> -->
        <el-container>
          <img @click="homePage" style="width: 262px; height: 60px; margin-right: 150px;cursor: pointer;"
            :src="getImgUrl(this.logoImage)" alt="" />
          <div style="padding-top: 8px">
            <el-input v-model="search" :placeholder="$t('m.pleaseContent')" style="width: 360px; color: #ee0a24" />
            <el-button type="primary" icon="el-icon-search" style="height: 40px" @click="searchList">{{ $t("m.search")
              }}</el-button>
            <el-button type="primary" icon="el-icon-search" style="height: 40px" @click="showSearch">{{
              $t("m.advancedSearch")
            }}</el-button>
          </div>
        </el-container>
      </div>
      <div class="main">
        <div class="img_div"></div>
        <!--  v-if="meauIndex != 'search'" -->
        <template>
          <div class="meau">
            <div class="left_all">
              <!-- {{ $t("m.allClassification") }} -->
              <div class="sort_button" v-for="item in sortList" :key="item.id">
                <div class="sort_button_title">{{ item.name }}</div>
                <div v-show="sortshow">
                  <ul class="sort_div">
                    <div style="height: 3px;background: rgb(35, 172, 255);"></div>
                    <li v-for="(item2, index2) in item.lister" :key="index2">
                      <p>{{ item2.listOne.name }}</p>
                      <p v-if="item2.listTwo">{{ item2.listTwo.name }}</p>
                      <div class="sort_con">
                        <div class="sort_con_title">{{ item2.listOne.name }}</div>
                        <ul class="sort_con_ul">
                          <li v-for="(item3, index3) in item2.listOne.list" :key="index3"
                            @click="search_sort(item3.id)">
                            {{ item3.name }}
                          </li>
                        </ul>
                        <div style="text-align: center;font-size: 12px;" v-if="item2.listOne.list.length <= 0">暂无分类
                        </div>
                        <div v-if="item2.listTwo">
                          <div class="sort_con_title">{{ item2.listTwo.name }}</div>
                          <ul class="sort_con_ul">
                            <li v-for="(item3, index3) in item2.listTwo.list" :key="index3"
                              @click="search_sort(item3.id)">{{ item3.name }}</li>
                          </ul>
                          <div style="text-align: center;font-size: 12px;" v-if="item2.listTwo.list.length <= 0">暂无分类
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <ul class="sort_div">
                <li v-for="(item,index) in sortList" :key="index">
                  <div class="sort_title">{{item.name}}</div>
                  <ul class="sort_div sort_two">
                    <li v-for="(item2,index2) in item.list" :key="index2">
                      <div class="sort_title">{{item2.name}}</div>
                      <div class="sort_con">
                        <div class="sort_con_title">{{item2.name}}</div>
                        <ul class="sort_con_ul">
                          <li v-for="(item3,index3) in item2.list" :key="index3">{{item3.name}}</li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul> -->

            </div>
            <!-- <el-aside width="200px"> -->

            <!-- <div
              class="left_all"
              @mouseover="isShowMenu = true"
              v-show="!isShowMenu"
            >
              {{ $t("m.allClassification") }}
            </div>
            <div class="left_div" v-show="isShowMenu">
              <div
                @click="toSearchPage(item.typeCode)"
                v-for="(item, index) in menus"
                :key="index"
                @mouseleave="unShow"
                @mouseover="showMsg(item.typeCode)"
                class="left_meun"
              >
                {{ item.name }}
              </div>
            </div> -->
            <!-- </el-aside> -->
            <el-main>
              <div class="category">
                <div class="clickStyle menuSize" @click="homePage" style="margin-left: 30px;"
                  :class="meauIndex == 0 ? 'active' : ''">
                  {{ $t("m.homePage") }}
                </div>
                <div v-for="(topType, index) in topTypes" :key="index">
                  <div class="clickStyle menuSize" style="overflow: hidden;"
                    :class="meauIndex == topType.id ? 'active' : ''" @click="menuPage(topType)">
                    {{ topType.name }}
                  </div>
                </div>
              </div>
            </el-main>
          </div>
        </template>
      </div>
    </div>
    <!-- 固定定位 -->
    <div class="drag-box window" ref="window" id="drag" v-if="this.$route.path != '/detail'" @mousedown="dragStart"
      @touchstart="dragStart">
      <img :src="getImgUrl(this.relationImage)" alt="" />
      <div class="hr_div"></div>
      <ul class="h4_div">
        <li v-for="(item, index) in contactList" :key="index">
          <div v-if="item.name != 'QQ'">{{ item.name }}：{{ item.value }}</div>
        </li>
        <li>
          <!-- <el-button
            @click="toPay"
            type="primary"
            plain
            size="small"
            class="login_button"
            style="margin-left: 22px; width: 100px"
            >支付方式</el-button
          > -->
          <el-button v-if="QQ" type="primary" plain size="small" class="login_button"
            style="margin-left: 22px; width: 100px">
            <a :href="'tencent://message/?uin=' + QQ + '&Site=Sambow&Menu=yes'" rel="nofollow">
              {{ $t("m.serviceQQ") }}
            </a>
          </el-button>
        </li>
      </ul>

    </div>
  </div>
</template>
<script>
export default {
  props: ["showMenu", "content"],
  data() {
    return {
      uname: localStorage.getItem("userName"),
      isShowMenu: true,
      isHome: true,
      active: "",
      search: this.content,
      carousels: [],
      isShow: false,
      menus: [],
      topTypes: [],
      meunItems: [],
      timer_a: "",
      timer_b: "",
      logoImage: "",
      meauIndex: this.$route.query.meauIndex || 0,
      sortshow: true,
      sortList: [],
      relationImage: "",
      contactList: [],
      QQ: '',
    };
  },
  created() {
    // console.log(this.$route.path);
    // console.log('导航触发');
    // console.log("search:" + this.content);
    // this.getTopType();
    this.selectImageList();
    this.getCategory();
    this.getshowAll();
    this.getCardDetail();
    this.selectImageList11();
    // if (sessionStorage.getItem("topCategory")) {
    //   let topCategory = sessionStorage.getItem("topCategory");
    //   this.topTypes = JSON.parse(topCategory);
    //   // console.log(this.topTypes, "this.topTypes");
    // } else {
    //   // console.log(456);

    // }
  },
  methods: {
    dragStart(event) {
      // 阻止默认的拖拽行为
      event.preventDefault();

      // 保存鼠标或触摸的初始位置
      this.startX = event.touches ? event.touches[0].clientX : event.clientX;
      this.startY = event.touches ? event.touches[0].clientY : event.clientY;

      // 保存窗口的初始位置
      this.startWindowLeft = this.$refs.window.offsetLeft;
      this.startWindowTop = this.$refs.window.offsetTop;

      // 添加事件监听
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('touchmove', this.drag);
      document.addEventListener('mouseup', this.dragEnd);
      document.addEventListener('touchend', this.dragEnd);
    },
    drag(event) {
      // 计算移动的距离
      const dx = event.touches ? event.touches[0].clientX : event.clientX;
      const dy = event.touches ? event.touches[0].clientY : event.clientY;

      const newLeft = this.startWindowLeft + dx - this.startX;
      const newTop = this.startWindowTop + dy - this.startY;

      // 更新窗口位置
      this.$refs.window.style.left = `${newLeft}px`;
      this.$refs.window.style.top = `${newTop}px`;
    },
    dragEnd() {
      // 移除事件监听
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('touchmove', this.drag);
      document.removeEventListener('mouseup', this.dragEnd);
      document.removeEventListener('touchend', this.dragEnd);
    },
    // 获取全分类
    getshowAll() {
      this.axios({
        url: "/v1/commodityType/showAll",
        method: "get",
        params: {
          lag: this.$i18n.locale,
        },
      }).then((res) => {
        console.log(res, "获取全分类");
        let sortList = [];
        let list = res.data.data.list;
        for (let i = 0; i < list.length; i++) {
          sortList.push(list[i]);
          sortList[i].lister = [];
          for (let y = 0; y < list[i].list.length; y++) {
            if (list[i].list.length == 1) {
              sortList[i].lister.push({ listOne: list[i].list[y], listTwo: null });
            } else {
              if ((y+1) % 2 != 0) {
                if (y + 1 <= list[i].list.length) {
                  sortList[i].lister.push({ listOne: list[i].list[y], listTwo: list[i].list[y + 1] });
                } else {
                  console.log("这里是？")
                  sortList[i].lister.push({ listOne: list[i].list[y], listTwo: null });
                }
              }
            }
          }
        }
        // console.log('结果',sortList);
        this.sortList = sortList;
      });
    },
    // 获取类别
    getCategory() {
      this.axios({
        url: "/v1/Bands/getOtherTop",
        method: "get",
        params: {
          lag: this.$i18n.locale,
        },
      }).then((res) => {
        // console.log(res, "获取类别");
        if (res.data.code == 200) {
          this.topTypes = res.data.data.list;
          let topCategory = JSON.stringify(this.topTypes);
          localStorage.setItem("topCategory", topCategory);
        }
      });
    },
    getImgUrl(img) {
      return process.env.VUE_APP_URL + "/v1/commodityManage/previewImage?path=" + img;
    },
    selectImageList() {
      this.axios({
        method: "get",
        url: "/v1/imageConfig/selectImageConfig",
        params: {
          postion: 10
        },
      }).then((res) => {
        // console.log('log',res);
        for (let i = 0; i < res.data.data.list.length; i++) {
          if (res.data.data.list[i].positionNo === "10" && res.data.data.list[i].display) {
            this.logoImage = res.data.data.list[i].imageUrl;
          }
        }
      });
    },
    selectImageList11() {
      this.axios({
        method: "get",
        url: "/v1/imageConfig/selectImageConfig",
        params: {
          postion: 11
        },
      }).then((res) => {
        // console.log('联系logo',res);
        for (let i = 0; i < res.data.data.list.length; i++) {
          if (res.data.data.list[i].positionNo === "11" && res.data.data.list[i].display) {
            this.relationImage = res.data.data.list[i].imageUrl;
          }
        }
      });
    },
    getCardDetail() {
      this.axios({
        method: "get",
        url: "/v1/config/list",
        params: {
          lag: this.$i18n.locale == "zh_CN" ? true : false,
          page: 1,
          size: 10,
          postion: 'link',
        },
      }).then((res) => {
        // console.log('联系方式',res)
        let list = res.data.data.list;
        this.contactList = res.data.data.list;
        for (let i = 0; i < list.length; i++) {
          if (list[i].name == 'QQ') {
            this.QQ = list[i].value;
          }
        }
      });
    },
    search_sort(id) {
      let that = this;
      that.sortshow = false;
      setTimeout(function () {
        that.sortshow = true;
        that.$router.push({
          path: "/search",
          query: { id: id },
        });
      }, 100);
    },
    searchList() {
      // console.log(this.search);
      this.$router.push({
        path: "/search",
        query: { search: this.search },
      });
    },
    showSearch() {
      console.log("点了")
      this.$emit('switchSeach')
      // this.$router.push({
      //   path: "/search",
      //   query: { search: this.search },
      // });
    },
    toCollect() {
      let token = localStorage.getItem("token");
      if (!token) {
        return this.$message({
          message: this.$i18n.locale == "zh_CN" ? "请先登录" : "Please log in first",
          type: "info",
        });
      }
      this.$router.push({ path: "/collect" });
    },
    toSearchPage(codeType) {
      this.$emit("getChildValue", { value: codeType });
      this.$router.push({ path: "/search", query: { codeType: codeType } });
    },
    getTopType() {
      this.axios({
        method: "get",
        url: "/v1/front/manage/queryMenuList",
        params: {
          lag: this.$i18n.locale,
        },
      }).then((res) => {
        this.topTypes = res.data.data.list;
      });
    },
    getTypeByFirst(typeCode) {
      this.axios({
        method: "get",
        url: "/v1/front/manage/getTypeByFirst",
        params: {
          lag: this.$i18n.locale,
          typeCode: typeCode,
          page: 1,
          size: 1000,
        },
      }).then((res) => {
        this.meunItems = res.data.data.list;
      });
    },
    personal() {
      let token = localStorage.getItem("token");
      if (!token) {
        return this.$message({
          message: this.$i18n.locale == "zh_CN" ? "请先登录" : "Please log in first",
          type: "info",
        });
      }
      this.$router.push("/personal");
    },
    // 首页
    homePage() {
      this.$router.push({
        path: "/",
        query: { meauIndex: 0, content: this.search },
      });
      if (this.$route.path == "/") {
        // this.$router.push("/");
        this.$router.go(0);
      }
      // this.meauIndex = 0;
      // this.$emit("changeMeauIndex", 0, this.search);
    },
    menuPage(topType) {
      // console.log(topType, "this.topType");
      this.meauIndex = topType.id;
      // console.log(this.meauIndex)
      let content = JSON.stringify(this.content);
      // this.$router.push("/");
      this.$router.push({
        path: "/",
        query: { meauIndex: this.meauIndex, content: content },
      });
      if (this.$route.path == "/") {
        this.$router.go(0);
      }
      // this.$emit("changeMeauIndex", this.meauIndex,content );
    },
    login() {
      if (this.$route.path !== "/login") {
        this.$router.push("/login");
      }
    },
    register() {
      if (this.$route.path !== "/register") {
        this.$router.push("/register");
      }
    },
    toCart() {
      let token = localStorage.getItem("token");
      if (!token) {
        return this.$message({
          message: this.$i18n.locale == "zh_CN" ? "请先登录" : "Please log in first",
          type: "info",
        });
      }
      this.$router.push("/shopCart");
    },
    handleCommand(command) {
      localStorage.setItem("lag", command);
      location.reload();
    },
    showMsg(typeCode) {
      this.isShow = true;
      this.isShowMenu = true;
      this.getTypeByFirst(typeCode);
    },
    unShow() {
      this.isShow = false;
      if (!this.isHome) {
        this.isShowMenu = false;
      }
    },
    out() {
      this.$confirm(this.$t("m.areYouSureToExit"), this.$t("m.tips"), {
        confirmButtonText: this.$t("m.determine"),
        cancelButtonText: this.$t("m.cancel"),
        type: "warning",
      }).then(() => {
        this.axios({
          method: "post",
          url: "/v1/webUser/userOut",
        }).then((res) => {
          if (res.data.code === 200) {
            localStorage.removeItem("userName");
            localStorage.removeItem("token");
            location.reload();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
      });
    },
  },
};
</script>
<style scoped>
.active {
  color: rgb(35, 172, 255) !important;
}

.category {
  display: flex;
  align-items: center;
  line-height: 40px;
}

.category>div {
  margin: 0 24px;
}

span,
a {
  cursor: pointer;
}

.n-header {
  background: #f5f5f5;
  min-height: 30px;
  line-height: 30px;
}

.n-header span {
  margin-right: 10px;
}

img {
  vertical-align: middle;
}

.s_header {
  margin-top: 20px;
}

.li_div {
  width: 100px;
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 15px;
  overflow: hidden;
}

.lidiv_div {
  padding: 0 2px;
  text-align: center;
  line-height: 20px;
  height: 20px;
  border: 1px solid rgb(217, 218, 220);
  color: rgb(135, 135, 136);
}

@media only screen and (min-width: 1200px) {
  .el-col-lg-11 {
    margin-left: -20%;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.show-menu {
  position: relative;
  z-index: 999;
  background-color: #ffffff;
  border-top: 0;
  width: 670px;
  padding-top: 6px;
  padding-left: 20px;
  text-align: left;
  height: 400px;
}

.el-main {
  padding: 0;
}

.left_meun {
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.left_meun:hover {
  background-color: white;
  cursor: pointer;
  color: black;
}

.left_all {
  color: white;
  margin: 0 auto;
  width: 200px;
  height: 37px;
  background-color: rgb(35, 172, 255);
  cursor: pointer;
  position: relative;
  top: 3px;
  padding-bottom: 3px;
  display: flex;
}

.sort_button {
  width: 100px;
  height: 37px;
}

.sort_button:hover .sort_div {
  display: block;
}

.sort_button:hover .sort_button_title {
  color: #fc5531;
}

.sort_button_title {
  line-height: 16px;
  text-align: center;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort_div {
  display: none;
  /* display: block; */
  width: 300px;
  min-height: 431px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 37px;
  z-index: 20;
  background: #505050;
  color: #ffffff;
}

.sort_div>li {
  width: 100%;
  display: flex;
}

.sort_div>li:hover {
  /* background: rgb(230, 0, 19); */
  background-color: rgb(35, 172, 255);
}

.sort_div>li:hover>.sort_con {
  display: block;
}

.sort_div>li>p {
  width: 50%;
  line-height: 28px;
  font-size: 13px;
  padding: 0 10px;
  margin: 0;
  height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sort_con {
  display: none;
  position: absolute;
  left: 300px;
  top: 3px;
  width: 500px;
  min-height: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  padding: 10px;
  color: #333333;
}

.sort_con_title {
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 10px;
}

.sort_con_ul {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 50px;
}

.sort_con_ul>li {
  margin: 0 10px 10px;
  border-bottom: 1px solid #dddddd;
  font-size: 12px;
  line-height: 1.2;
  padding: 5px 10px;
}

.sort_con_ul>li:hover {
  /* color: rgb(230, 0, 19); */
  /* color: rgb(35, 172, 255); */
  background: rgb(35, 172, 255);
  border-color: rgb(35, 172, 255);
  color: #fff;
  border-radius: 5px;
}

.left_div {
  background-color: #1d1d1d8c;
  color: #fff;
  position: relative;
  z-index: 100;
}

@media only screen and (min-width: 1200px) {
  .el-container {
    margin: auto;
    width: 1220px;
  }
}

.main {
  margin-top: 20px;
  position: relative;
  border-bottom: 3px solid rgb(35, 172, 255);
}

/* .img_div {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 99;
  background-color: rgb(35, 172, 255);
  height: 3px;
} */

.clickStyle:hover {
  cursor: pointer;
  color: rgb(35, 172, 255);
}

.menuSize {
  font-size: 17px;
  line-height: 20px;
  padding: 10px 0;
  text-align: center;
}

.meau {
  width: 1220px;
  display: flex;
  margin: 0 auto;
  align-items: flex-end;
}

.drag-box {
  position: absolute;
  /* top: 200px;
  right: 1%; */
  right: 1%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 999;
  width: 190px;
  /* height: 350px; */
  text-align: center;
  border: 1px solid #eee;
}

.drag-box>img {
  display: block;
  width: 100%;
  height: auto;
}

.hr_div {
  background-color: #eee;
  height: 1px;
  margin-top: 10px;
}

.h4_div {
  text-align: left;
  padding-left: 20px;
}

.h4_div li {
  line-height: 50px;
}

.login_button {
  width: 80px;
  height: 25px;
  line-height: 7px;
}

.login_button a {
  color: #409EFF;
}

.login_button:hover a {
  color: #ffffff;
}

/* .window {
  position: absolute;
  width: 300px;
  height: 200px;
  border: 1px solid #000;
  background: #fff;
  user-select: none;
} */

.titlebar {
  height: 20px;
  cursor: move;
}
</style>
